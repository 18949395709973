export const layoutAccess = {
  general: 0,
  protected: 1,
};

export const DefaultPlatformSettings = {
  ListingFeePercent: 3,
};

export const layoutModeTypes = {
  LIGHTMODE: "light",
  DARKMODE: "dark",
};

export const walletType = {
  metamask: "metamask",
  walletConnect: "walletconnect",
  suiWallet: "suiWallet",
  web3Auth: "web3Auth",
};

export const preloaderTypes = {
  ENABLE: "enable",
  DISABLE: "disable",
};

export const s3FolderTypes = {
  S3NftFolder: "optimized_media",
  S3NftMediaFolder: "media",
  S3ProfileFolder: "profile",
  S3KycFolder: "kyc",
  S3RawFolder: "raw",
  S3CoverFolder: "cover",
  S3CollectionFolder: "collection",
  S3GroupFolder: "group",
  S3TutorialFolder: "tutorial",
  custom_task: "custom_task",
  S3CategoryFolder: "category",
  S3GiftIconFolder: "gift_icon",
};

export const seedTwitterUrl = {
  stage: "https://x.com/sinamn13755",
  main: "https://x.com/SeedPhoto",
};

export const EnumToastType = {
  Info: 0,
  Warning: 1,
  Error: 2,
  Success: 3,
};

export const EnumConsoleType = {
  Default: 0,
  Info: 1,
  Warning: 2,
  Error: 3,
  Success: 4,
};

export const EnumVerifications = {
  Rejected: 1,
  Pending: 2,
  Accepted: 3,
  NoAccess: 4,
};

export const EngagementLevelArtist = {
  SignUp: 1,
  Verification: 2,
  Upload: 3,
  Mint: 4,
  List: 5,
  Sell: 6,
};

export const skeletonMainConfigs = {
  baseColor: "#232631",
  highlightColor: "#777D90",
};

export const coverTypes = {
  Seed: 1,
  Favorite: 2,
  Feed: 3,
  Minted: 4,
  Collected: 5,
  Instagram: 6,
};

export const member = {
  None: 0,
  Pro: 1,
  premium: 2,
  Gold: 3,
  Diamond: 4,
};

export const notificationSettingsTypes = {
  UploadNft: "UploadNft",
  CollectNft: "CollectNft",
  List: "List",
  UnList: "UnList",
  Mint: "Mint",
  FinishAuction: "FinishAuction",
  Bid: "Bid",
  Buy: "Buy",
  Archive: "Archive",
  ReferralUploadNft: "ReferralUploadNft",
  ReferralCollectNft: "ReferralCollectNft",
  ReferralList: "ReferralList",
  ReferralUnList: "ReferralUnList",
  ReferralMint: "ReferralMint",
  ReferralFinishAuction: "ReferralFinishAuction",
  ReferralBid: "ReferralBid",
  RefferalBuy: "RefferalBuy",
  NewFollower: "NewFollower",
  Burn: "Burn",
  AuctionEnded: "AuctionEnded",
  AuctionUnListed: "AuctionUnListed",
  UnListAuctionBeforeEnd: "UnListAuctionBeforeEnd",
  BuyWithTarget: "BuyWithTarget",
  BidHigher: "BidHigher",
  ProMember: "ProMember",
  GoldMember: "GoldMember",
  PremiumMember: "PremiumMember",
  DiamondMember: "DiamondMember",
  SendVerification: "SendVerification",
  PhotographerVerified: "PhotographerVerified",
  PhotographerRejected: "PhotographerRejected",
  ReferralJoined: "ReferralJoined",
  ReferralVerified: "ReferralVerified",
  Bridge: "Bridge",
};

export const notificationSettingsMethods = {
  InApp: 1,
  Email: 2,
};

export const NotificationType = {
  Sell: 1,
  List: 2,
  Following: 3,
  Bid: 4,
  Like: 5,
  Purchase: 6,
  Finish_Auction: 7,
  CashBack: 8,
  Accept_Photographer: 9,
  Upload_Nft: 10,
  UnList: 11,
  Mint: 12,
  Archive: 13,
  Referral_Mint: 20,
  Referral_List: 21,
  Referral_UnList: 22,
  Referral_Buy: 23,
  Referral_Bid: 24,
  Referral_FinishAuction: 25,
  Accept_Promotion: 26,
  Reject_Promotion: 27,
  Pending_Promotion: 28,
  Earned_Seed_Token: 29,
  Reject_Photographer: 30,
  Bridge: 31,
  Referral_Joined: 32,
};

export const chainList = {
  BSCMainnet: 56,
  BSCTestnet: 97,
  PolygonMainnet: 137,
  PolygonTestnet: 80002,
  PolygonTestnetOld: 80001,
  EthereumMainnet: 1,
  EthereumTestnet: 11155111,
  EthereumTestnetOld: 5,
  SuiChainMainnet: 101,
  SuiChainTestnet: 103,
  SuiDefualtChain: 102,
  SeedToken: 73656564,
  // SuiTestnet: 3,
};

export const EnumChains = {
  BSCMainnet: "BSC",
  EthereumMainnet: "Ethereum",
  PolygonMainnet: "Polygon",
  SuiChain: "Sui",
};

export const ComponentTypes = {
  DiscoverNft: 1,
  CreateNft: 2,
  CollectedNft: 3,
  CollaborationNft: 4,
  ArchiveNft: 5,
  FavoriteNft: 6,
  HotBidNft: 7,
  MyCollection: 8,
  HomeCollection: 9,
  ExclusiveCollection: 10,
  Follower: 11,
  Following: 12,
  SimilarArtworks: 13,
  All: 14,
  CollectionDetails: 15,
  FeaturedArtists: 16,
  CollectionPage: 17,
  RecentlySold: 18,
  NewListing: 19,
  FavoriteCollection: 20,
  Referrals: 21,
  ManageMyBids: 22,
};

// export const TransactionType = {
//   Mint: 1,
//   List_Nft_FixedPrice: 10,
//   Unlist_Nft_FixedPrice: 11,
//   List_Nft_Start_Auction: 20,
//   Unlist_Nft_Finish_Auction: 21,
//   Auction_Bid: 30,
//   Cancel_Auction_Bid: 31,
//   Finish_Auction: 32,
//   MarketPlaceOwner_Commission: 40,
//   Referal_Commission: 41,
//   OwnerShip_Share: 42,
//   Royalty_Commission: 43,
//   Burn_Nft: 50,
//   Transfer_Nft: 60,
//   Send_Seed_Token: 70,
//   Bridge_Nft: 80,
//   LockAndEarn_Nft: 90,
//   Buy_Nft: 100,
// };

export const TransactionType = {
  Mint: 1,
  List_NFT_Fixed_Price: 10,
  Unlist_NFT_Fixed_Price: 11,
  List_NFT_Start_Auction: 20,
  Unlist_NFT_Finish_Auction: 21,
  Auction_Bid: 30,
  Cancel_Auction_Bid: 31,
  Finish_Auction: 32,
  MarketPlaceOwner_Commission: 40,
  Referral_Commission: 41,
  OwnerShip_Share: 42,
  Royalty_Commission: 43,
  Burn_NFT: 50,
  Transfer_NFT: 60,
  Send_Seed_Token: 70,
  Bridge_NFT: 80,
  Lock_And_Earn_NFT: 90,
  Buy_NFT: 100,
};

export const topRankTypes = {
  Referral: 1,
  Sell: 2,
  Minted: 3,
  Listed: 4,
  Collected: 5,
  Artist: 6,
  Artwork: 7,
};

export const EnumBadges = {
  None: 0,
  Pro: 1,
  Premium: 2,
  Gold: 3,
  Diamond: 4,
};

export const EnumRoles = {
  SuperAdmin: "SuperAdmin",
  Admin: "Admin",
  Artist: "Artist",
  Collector: "Collector",
  Curator: "Curator",
  Communities: "Communities",
  VideoUploader: "VideoUploader",
  TransferNFT: "TransferNFT",
};

export const EnumTimerStyle = {
  default: 0,
  museum: 1,
  inCard: 2,
};

export const EnumRatioType = {
  Landscape: 1,
  Portrait: 2,
};

export const EnumVideoUrls = {
  Mint: "https://s3.ap-northeast-1.amazonaws.com/tutorial.seed.photo/04 - Mint.mp4",
  Unlist:
    "https://s3.seedphoto.dev/tutorial/a7dd5a3632884dc486eb4c12a6643099.mp4",
  List: "https://s3.ap-northeast-1.amazonaws.com/tutorial.seed.photo/05 - List.mp4",
  Buy: "https://s3.seedphoto.dev/tutorial/45287ed3057e46948f2269538728e2fb.mp4",
  Bid: "https://s3.seedphoto.dev/tutorial/3852c2b3efd848d1ababb33a37be3ce6.mp4",
  Finish_Auction:
    "https://s3.seedphoto.dev/tutorial/871707e3a99a48a1bd60a967cf269d8b.mp4",
  Bridge:
    "https://s3.seedphoto.dev/tutorial/51a58273aa68434dad0534dab270a06b.mp4",
  Upload:
    "https://s3.ap-northeast-1.amazonaws.com/tutorial.seed.photo/03 - Upload.mp4",
  Collaborative:
    "https://s3.seedphoto.dev/tutorial/dc2450a84aa4422b8051ecbabf7c3c45.mp4",
  Collection:
    "https://s3.seedphoto.dev/tutorial/b0845ec750404e30aca97deb7713a10d.mp4",
  CreateWallet:
    "https://s3.ap-northeast-1.amazonaws.com/tutorial.seed.photo/01 - Create metamask wallet.mp4",
  RegisterPhotographer:
    "https://s3.seed.photo/tutorial/3bcb8f4db4514f1f8ee03be5cb1865af.mp4",
  UserName:
    "https://s3.seedphoto.dev/tutorial/5f619356d1654295af5b29495b763674.mp4",
  walletConnect:
    "https://s3.seedphoto.dev/tutorial/a9ceff56ae954b5092ffc5f813a3e373.mp4",
  metaMask:
    "https://s3.seedphoto.dev/tutorial/be7d950a55304f2d86bdb8c83664300b.mp4",
};

export const EnumVideoIcon = {
  default: 0,
  palyButton: 1,
};

export const allowedRowFileTypes = [
  ".3fr",
  ".ari",
  ".arw",
  ".bay",
  ".braw",
  ".crw",
  ".cr2",
  ".cr3",
  ".cap",
  ".data",
  ".dcs",
  ".dcr",
  ".dng",
  ".drf",
  ".eip",
  ".erf",
  ".fff",
  ".gpr",
  ".iiq",
  ".k25",
  ".kdc",
  ".mdc",
  ".mef",
  ".mos",
  ".mrw",
  ".nef",
  ".nrw",
  ".obm",
  ".orf",
  ".pef",
  ".ptx",
  ".pxn",
  ".r3d",
  ".raf",
  ".raw",
  ".rwl",
  ".rw2",
  ".rwz",
  ".sr2",
  ".srf",
  ".srw",
  ".tif",
  ".x3f",
];

//twiter

export const TwitterSections = {
  Like: 1,
  Reply: 2,
  RePost: 3,
  Follow: 4,
  Hashtag: 5,
  InviteUser: 6,
  Quote: 7,
};

export const Leagues = {
  None: 0,
  Bronze: 1,
  Silver: 2,
  Gold: 3,
  Platinum: 4,
};

export const RatingSections = {
  Upload: 1,
  Mint: 2,
  List: 3,
  UnList: 4,
  Bid: 5,
  Buy: 6,
  FinishAuction: 7,
};

export const WithdrawalStatuses = {
  Pending: 1,
  Rejected: 2,
  Cancelled: 3,
  Accepted: 4,
};

export const PromotionPackagesAddress = {
  promotion1: "0x2807DE5254310be357730A6641BfC0c13037088a",
  promotion2: "0x66314f3CAb6d5a33a2D4eebe6cF73e88e85AE40a",
  promotion3: "0x1f40098BD2cE7042546A557eafa89d449445Db0C",
};

export const StakesLevel = {
  Level1: 50,
  Level2: 500,
  Level3: 2000,
  Level4: 10000,
};

export const StakeStatuses = {
  Pending: 1,
  Cancelled: 2,
  Accepted: 3,
};

export const StakeTypes = {
  Deposit: 1,
  Withdrawal: 2,
};

export const CustomTasksSorts = {
  Id: 1,
  Title: 2,
  Description: 3,
  StartDate: 4,
  EndDate: 5,
  SeedX: 6,
  Type: 7,
  Enable: 8,
  Brand: 9,
  CreatedAt: 10,
  UpdatedAt: 11,
};

export const UserTaskStatuses = {
  Pending: 1,
  Rejected: 2,
  Accepted: 3,
};

export const CustomTaskTypes = {
  TwitterLike: 1,
  TwitterRetweet: 2,
  TwitterQuote: 3,
  VisitLink: 4,
  InstagramComment: 5,
  UploadScreenShot: 6,
};

export const UserCustomTasksSorts = {
  Id: 1,
  UserName: 2,
  Twitter: 3,
  Title: 4,
  CustomTaskId: 5,
  Media: 6,
  CreatedAt: 7,
  StatusId: 8,
};
