import {
  fetchXSRF,
  getCookie,
  setItem,
  setItemCookie,
} from "@/config/helpers/api_helper";
import {
  selectGetSettingsData,
  selectLayoutModeTypesData,
} from "@/config/helpers/createReduxSelectors";
import {
  config3,
  initialSettingsDataConfig,
  stage,
} from "@/config/helpers/web3Utiles";
import { changeLayoutMode, getSettings } from "@/store/actions";
import { isEmpty } from "lodash";
import dynamic from "next/dynamic";
import Head from "next/head";
import { useRouter } from "next/router";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { layoutAccess } from "../constants/layout";
import { defaultSeoMeta } from "../constants/seo";
import { api_stage } from "@/config/config";
import { getFormattedDateTime } from "../common/utiles";

const Header = dynamic(() => import("./Header"));
const Footer = dynamic(() => import("./Footer"));
const ConfirmAbove18Age = dynamic(() => import("../common/confirmAbove18Age"), {
  ssr: false,
});
const AnnouncementNews = dynamic(() => import("../common/announcementNews"), {
  ssr: false,
});
const SvgIconPack = dynamic(() => import("../common/svgIconPack"), {
  ssr: false,
});

function Layout({
  children,
  accessMode = layoutAccess.general,
  needRedirect = true,
  seoMetas = defaultSeoMeta,
  noSeoTag = false,
  pageTitle = "SEED - 1st NFT Photography Marketplace Trade, Buy & Sell Art",
  showMeta = true,
  showWC = true,
  showSui = true,
  showW3A = true,
}) {
  const dispatch = useDispatch();
  const router = useRouter();
  // const [pageUrl, setPageUrl] = useState();

  const { successSettings, Settings, loadingSettings } = useSelector(
    selectGetSettingsData
  );

  useEffect(() => {
    if (isEmpty(Settings)) {
      dispatch(getSettings());
    }
  }, [Settings, dispatch]);

  useEffect(() => {
    if (!isEmpty(Settings) && !config3) {
      initialSettingsDataConfig(Settings);
    }
  }, [Settings]);

  const token =
    typeof window !== "undefined" &&
    getCookie("authentication") !== "" &&
    getCookie("authentication");

  const xsrf =
    typeof window !== "undefined" &&
    getCookie("xsData") !== "" &&
    getCookie("xsData");

  const { layoutModeType } = useSelector(selectLayoutModeTypesData);

  useEffect(() => {
    if (layoutModeType) {
      dispatch(changeLayoutMode(layoutModeType));
    }
  }, [layoutModeType, dispatch]);

  // const fetchXSRF = async () => {
  //   const baseApi =
  //     stage === "stage1" ? api_stage.API_URL_STAGE1 : api_stage.API_URL_MAIN;
  //   let xsrf = await fetch(`${baseApi}api/xsrf-token`, {
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       AuthorizationAntiforgery: `sfCFCPEcuK7aXwoAkBLLnWxLQZkGSdiIS0E4tcDM2T6qqtAnvF0df93u+bRTWBnxQ69bA07k8VIjGNWM07rPmA==${getFormattedDateTime()}`,
  //     },
  //   })
  //     .then(async (res) => {
  //       console.log("res", res);
  //       let data = await res.json();

  //       console.log(data);

  //       if (res.ok) {
  //         sessionStorage.setItem("xsData", data?.HeaderToken);
  //         setItemCookie("Seed.Antiforgery", data?.CookieToken, true);
  //       }

  //       return res;
  //     })
  //     .catch((err) => {
  //       console.error("fetch error ", err);
  //     });
  // };

  // useEffect(() => {
  //   if (isEmpty(xsrf) || xsrf === null) {
  //     console.log("no xsrf", getCookie("xsData"), xsrf === null);
  //     fetchXSRF();
  //   }
  // }, [xsrf]);

  useEffect(() => {
    if (accessMode === layoutAccess.protected) {
      if (isEmpty(token)) {
        console.log("you are not authorized!");
        if (needRedirect) {
          router.replace("/");
        }
      }
    }
    // console.log("in layout nextjssssss");
  }, [token]);

  useEffect(() => {
    if (isEmpty(token)) {
      if (typeof window !== "undefined") {
        if (document.location.href.split("?")[1]) {
          if (document.location.href.split("?")[1].split("&")[0]) {
            if (
              document.location.href
                .split("?")[1]
                .split("&")[0]
                .split("referral=")[1]
            ) {
              setItem(
                "referralAddress",
                document.location.href
                  .split("?")[1]
                  .split("&")[0]
                  .split("referral=")[1]
              );
              router.replace("/");
              console.log(
                document.location.href
                  .split("?")[1]
                  .split("&")[0]
                  .split("referral=")[1]
              );
            }
          }
        }
      }
    }
    // console.log("in layout nextjssssss");
  }, [token]);

  const onChangeLayoutMode = (value) => {
    if (changeLayoutMode) {
      dispatch(changeLayoutMode(value));
    }
  };

  const memoizedHeader = useMemo(
    () => (
      <Header
        layoutModeType={layoutModeType}
        onChangeLayoutMode={onChangeLayoutMode}
        showMeta={showMeta}
        showWC={showWC}
        showSui={showSui}
        showW3A={showW3A}
      />
    ),
    [layoutModeType, onChangeLayoutMode, showMeta, showWC, showSui, showW3A]
  );

  const memoizedFooter = useMemo(() => <Footer />, []);

  const memoizedConfirmAbove18Age = useMemo(() => <ConfirmAbove18Age />, []);

  const memoizedAnnouncementNews = useMemo(() => <AnnouncementNews />, []);

  const memoizedSvgIconPack = useMemo(() => <SvgIconPack />, []);

  return (
    <>
      <Head>
        {/* End Google Tag Manager */}
        {/* , user-scalable=no */}
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        ></meta>

        {!noSeoTag ? (
          seoMetas.robotIndex ? (
            <>
              {/* Google tag (gtag.js) */}
              <script
                src="https://www.googletagmanager.com/gtag/js?id=AW-11158303109"
                defer
              />
              <script
                dangerouslySetInnerHTML={{
                  __html: `
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', 'AW-11158303109');
  `,
                }}
                id="google-analytics"
                strategy="afterInteractive"
                defer
              />

              {/* Google Tag Manager */}
              <script
                dangerouslySetInnerHTML={{
                  __html: `
            (function (w, d, s, l, i) {
              w[l] = w[l] || []; w[l].push({
                  'gtm.start':
                      new Date().getTime(), event: 'gtm.js'
              }); var f = d.getElementsByTagName(s)[0],
                  j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                      'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', 'GTM-WFL9GDV');
          `,
                }}
                id="google-tag-manager"
                strategy="afterInteractive"
                defer
              />

              <link
                rel="preload"
                as="image"
                href={`${seoMetas.preLoadImage}`}
                // imageSrcSet={`${seoMetas.preLoadImage}`}
                // imageSizes="100vw"
                fetchPriority="high"
              />

              <meta
                name="robots"
                content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
              />

              <link
                rel="canonical"
                href={`${seoMetas.canonical}`}
                key="canonical"
              />

              <title>{seoMetas.title}</title>

              <meta name="description" content={`${seoMetas.description}`} />

              <meta name="author" content="Seed.Photo" />

              <meta property="og:image" content={`${seoMetas.og_image}`} />
              <meta property="og:image:alt" content={`${seoMetas.og_title}`} />
              <meta property="og:title" content={`${seoMetas.og_title}`} />
              <meta property="og:url" content={`${seoMetas.og_url}`} />
              <meta property="og:site_name" content="Seed.Photo" />
              <meta property="og:locale" content="en_US" />
              <meta property="og:image:type" content="image/jpeg" />
              <meta
                property="og:description"
                content={`${seoMetas.description}`}
              />
              <meta property="og:image:width" content="1200" />
              <meta property="og:image:height" content="720" />

              <meta name="twitter:card" content="summary_large_image" />
              <meta
                property="twitter:domain"
                content={`${process.env.NEXT_PUBLIC_ENV_PROTOCOL}://${process.env.NEXT_PUBLIC_URL}`}
              />
              <meta name="twitter:site" content="@SeedPhoto" />
              <meta
                name="twitter:title"
                content={`${seoMetas.twitter_title}`}
              />
              <meta
                name="twitter:image"
                content={`${seoMetas.twitter_image}`}
              />
              <meta name="twitter:app:name:googleplay" content="Seed.Photo" />
              <meta name="twitter:app:id:googleplay" content="app.seed.photo" />
              <meta
                name="twitter:app:url:googleplay"
                content={`${seoMetas.og_url}`}
              />
              <meta name="twitter:image:alt" content={`${seoMetas.og_title}`} />
              <meta name="twitter:url" content={`${seoMetas.og_url}`} />
              <meta name="twitter:cta" content="View on Seed.Photo" />
              <meta
                name="twitter:description"
                content={`${seoMetas.description}`}
              />
            </>
          ) : (
            <meta name="robots" content="noindex" />
          )
        ) : (
          <>
            <meta name="robots" content="noindex" />
            <title>{pageTitle}</title>
          </>
        )}

        <meta httpEquiv="Cache-Control" content="no-cache" />
        <meta httpEquiv="expires" content="0" />
        <meta httpEquiv="pragma" content="no-cache" />

        {/* <meta name="viewport" content="width=device-width, initial-scale=1" /> */}
        <link rel="icon" href="/favicon.ico" />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#8f7a3c" />
        <meta name="apple-mobile-web-app-title" content="Seed Photo" />
        <meta name="application-name" content="Seed Photo" />
        <meta name="msapplication-TileColor" content="#8f7a3c" />
        <meta name="msapplication-TileImage" content="/mstile-144x144.png" />
        <meta
          name="theme-color"
          content="#8E793E"
          media="(prefers-color-scheme: light)"
        />
        <meta
          name="theme-color"
          content="#000"
          media="(prefers-color-scheme: dark)"
        />

        <link
          rel="apple-touch-startup-image"
          media="orientation: portrait"
          href="/apple-touch-icon.png"
        />

        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="mobile-web-app-capable" content="yes" />

        {/* pinterest */}
        <meta
          name="p:domain_verify"
          content="64b7e16b39a7509b8bb8e8937c61e04c"
        />

        <meta charSet="utf-8" />

        {/* <meta name="twitter:image:width" content="1200" /> */}
        {/* <meta content="Seed.Photo artists" name="twitter:author" /> */}

        <meta name="referrer" content="origin-when-cross-origin" />
        <meta
          httpEquiv="Permissions-Policy"
          content="geolocation=(self), microphone=()"
        />

        {!noSeoTag && (
          <>
            <script
              src="https://www.googletagmanager.com/gtag/js?id=G-ZK8WBN4KX3"
              defer
            />
            <script
              dangerouslySetInnerHTML={{
                __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag() {
                dataLayer.push(arguments);
            }
            gtag('js', new Date());
            gtag('config', 'G-ZK8WBN4KX3');
            `,
              }}
              strategy="afterInteractive"
              defer
            ></script>
          </>
        )}
      </Head>
      <div className="seed__content">
        {!noSeoTag && (
          <noscript
            dangerouslySetInnerHTML={{
              __html: `
            <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WFL9GDV" height="0" width="0" style="display:none;visibility:hidden"></iframe>
            `,
            }}
          ></noscript>
        )}

        {memoizedHeader}
        <main>{children}</main>
        {memoizedFooter}
        {memoizedConfirmAbove18Age}
        {memoizedAnnouncementNews}
      </div>

      {/* svg Icons */}
      {memoizedSvgIconPack}
      {/* <script src="https://cdn.lordicon.com/ritcuqlt.js" defer></script> */}
      {/* <script src="/lord-icon/ritcuqlt.js" defer></script> */}
    </>
  );
}

export default React.memo(Layout);
