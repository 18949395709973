import Image from "next/image";
import React from "react";
import FIAP from "@/assets/images/home/awardsPhoto/icons/FIAP-seed-awards-photo.png";
import EURASIA from "@/assets/images/home/awardsPhoto/icons/EURASIA-seed-awards-photo.png";
import Awards from "@/assets/images/home/awardsPhoto/icons/seed-awards-photo-2023.svg";
import Award_50000 from "@/assets/images/home/awardsPhoto/icons/50000-seed-awards-photo.webp";
import cupsAward from "@/assets/images/home/awardsPhoto/icons/cups-seed-awards-photo.png";
import winBadges from "@/assets/images/home/awardsPhoto/icons/medals-seed-awards-photo.png";
import FIAPbadge from "@/assets/images/home/awardsPhoto/icons/FIAPbadge.webp";

function AwardsGrantedLogos({ homeStyle }) {
  return (
    <>
      <div className={homeStyle["awards_logo"]}>
        <div className={homeStyle["logo_item"]}>
          <Image
            src={FIAP}
            alt="FIAP-seed-awards-photo"
            width={80}
            height={80}
            quality={100}
          />
        </div>
        <div className={homeStyle["logo_item"]}>
          <Image
            src={FIAPbadge}
            alt="FIAP-seed-awards-badge-photo"
            width={80}
            height={80}
            quality={100}
          />
        </div>
        <div className={homeStyle["logo_item"]}>
          <Image
            src={Awards}
            alt="seed-awards-photo-2023"
            width={150}
            height={80}
            quality={100}
          />
        </div>
        <div className={homeStyle["logo_item"]}>
          <Image
            src={Award_50000}
            alt="50000-seed-awards-photo"
            width={126}
            height={60}
            quality={100}
          />
        </div>
        <div className={homeStyle["logo_item"]}>
          <Image
            src={cupsAward}
            alt="cups-seed-awards-photo"
            width={80}
            height={80}
            quality={100}
          />
        </div>
        <div className={homeStyle["logo_item"]}>
          <Image
            src={EURASIA}
            alt="EURASIA-seed-awards-photo"
            width={80}
            height={80}
            quality={100}
          />
        </div>
        <div className={homeStyle["logo_item"]}>
          <Image
            src={winBadges}
            alt="win-badges-seed-awards-photo"
            width={121}
            height={60}
            quality={100}
          />
        </div>
      </div>
    </>
  );
}

export default AwardsGrantedLogos;
