import React, { useState } from "react";
import Museum_step1_dark from "@/assets/images/Museum/Museum_step1-dark.gif";
import Museum_step1 from "@/assets/images/Museum/Museum_step1.gif";
import Museum_step2_dark from "@/assets/images/Museum/Museum_step2-dark.gif";
import Museum_step2 from "@/assets/images/Museum/Museum_step2.gif";
import Museum_step3_dark from "@/assets/images/Museum/Museum_step3-dark.gif";
import Museum_step3 from "@/assets/images/Museum/Museum_step3.gif";
import Image from "next/image";

function WhatIsMuseumSection({ styles }) {
  const [activeStep, setActiveStep] = useState(1);

  const navigateToFormStep = (stepNumber) => {
    setActiveStep(stepNumber);
  };

  const getImageSource = () => {
    switch (activeStep) {
      case 2:
        return Museum_step2;
      case 3:
        return Museum_step3;
      default:
        return Museum_step1;
    }
  };

  const getImageSourceDark = () => {
    switch (activeStep) {
      case 2:
        return Museum_step2_dark;
      case 3:
        return Museum_step3_dark;
      default:
        return Museum_step1_dark;
    }
  };

  return (
    <div className={styles["museum"]}>
      <div className={styles["museum__guide"]}>
        <div className={styles["museum__guide_content"]}>
          <ul
            className={` ${styles["museum-stepper"]} ${styles["form-stepper-vertical"]}   text-center `}
          >
            {/* Step 1 */}
            <li
              className={`  ${styles["museum-stepper-list"]} ${
                activeStep === 1
                  ? styles["museum-stepper-active"]
                  : styles["museum-stepper-completed"]
              }`}
              step="1"
            >
              <div className={styles["museum__btn"]}>
                <span className={styles["museum-stepper-circle"]}></span>
                <div>
                  <button
                    className={` ${styles["label"]} ${styles["text-muted"]}  btn-navigate-museum-step`}
                    type="button"
                    onClick={() => navigateToFormStep(1)}
                  >
                    Step1: Innovative Concept
                  </button>
                </div>
              </div>
              {/* Step 1 Content */}
              <section
                id="step-1"
                className={` ${styles["museum__step"]} ${
                  activeStep === 1 ? "" : styles["d-none"]
                }`}
              >
                <div className={styles["museum__Description"]}>
                  The Museum introduces a revolutionary approach to NFT artwork.
                  SEED.Photo has redefined the idea of staking to align with its
                  platform, supporting a thriving community. When collectors
                  lend their NFT artwork to the Museum, they receive 20% of the
                  buying price immediately. This unique opportunity allows the
                  collectors to support the Museum while earning rewards.
                </div>
              </section>
            </li>
            {/* Step 2 */}
            <li
              className={` ${styles["museum-stepper-list"]} ${
                activeStep === 2
                  ? styles["museum-stepper-active"]
                  : activeStep === 3
                  ? styles["museum-stepper-completed"]
                  : styles["museum-stepper-unfinished"]
              }`}
              step="2"
            >
              <div className={styles["museum__btn"]}>
                <span
                  className={` ${styles["museum-stepper-circle"]} ${styles["text-muted"]}`}
                ></span>
                <div>
                  <button
                    className={` ${styles["label"]} ${styles["text-muted"]}  btn-navigate-museum-step`}
                    type="button"
                    onClick={() => navigateToFormStep(2)}
                  >
                    Step2: Flexible Lending Terms
                  </button>
                </div>
              </div>
              {/* Step 2 Content, default hidden on page load. */}
              <section
                id="step-2"
                className={` ${styles["museum__step"]}  ${
                  activeStep === 2 ? "" : styles["d-none"]
                }`}
              >
                <div className={styles["museum__Description"]}>
                  The artwork is lent for a period of 12 months, with the option
                  to extend the lending period for another 12 months if desired.
                  This flexibility ensures that collectors can continue to
                  benefit from the Museum&apos;s reward program. After 12
                  months, the NFT artwork will be released from the Museum and
                  sent back to the collector&apos;s wallet, ensuring they retain
                  ownership and control.
                </div>
              </section>
            </li>
            {/* Step 3 */}
            <li
              className={` ${styles["museum-stepper-list"]} ${
                activeStep === 3
                  ? styles["museum-stepper-active"]
                  : styles["museum-stepper-unfinished"]
              }`}
              step="3"
            >
              <div className={styles["museum__btn"]}>
                <span
                  className={` ${styles["museum-stepper-circle"]}  ${styles["text-muted"]}`}
                ></span>
                <div className={` ${styles["label"]} ${styles["text-muted"]} `}>
                  <button
                    className={` ${styles["label"]} ${styles["text-muted"]} btn-navigate-museum-step`}
                    type="button"
                    onClick={() => navigateToFormStep(3)}
                  >
                    Step3: Rewards in Original Currency
                  </button>
                </div>
              </div>
              {/* Step 3 Content, default hidden on page load. */}
              <section
                id="step-3"
                className={` ${styles["museum__step"]}  ${
                  activeStep === 3 ? "" : styles["d-none"]
                }`}
              >
                <div className={styles["museum__Description"]}>
                  Collectors will receive their reward not in USD currency, but
                  in the same currency used to purchase the NFT photograph. For
                  instance, if BNB was used to buy the artwork, collectors will
                  receive 20% of the purchase value directly to their BNB
                  wallets, allowing them to gain rewards in a familiar and
                  potentially more valuable form.
                </div>
              </section>
            </li>
          </ul>
        </div>
        {/* Photos */}
        <div className={styles["museum__guide_photos"]}>
          <Image
            className="some-icon"
            id="photos"
            src={getImageSource()}
            width={530}
            height={320}
            alt="Museum Step"
            loading="lazy"
          />
          <Image
            className="some-icon-dark"
            id="photos"
            src={getImageSourceDark()}
            width={590}
            height={358}
            alt="Museum Step"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
}

export default WhatIsMuseumSection;
