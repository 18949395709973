//ACCOUNT
export const GET_XSRF = "/api/xsrf-token";

export const POST_ACCOUNT_LOGIN = "/api/Account/Login";
export const GET_ACCOUNT_PROFILE = "/api/Account/Profile";
export const GET_ACCOUNT_COVER_PROFILE = "/api/Account/CoverProfile";
export const GET_ACCOUNT_USER_GALLARY = "/api/Account/UserGallary";
export const GET_ACCOUNT_ASSET_PORTFOLIO = "/api/Account/AssetPortfolio";
export const POST_ACCOUNT_EDIT_PROFILE = "/api/Account/EditUserProfile";
export const GET_ACCOUNT_REFERRAL_DASHBOARD_ARTIST =
  "/api/Account/UserReferralDashboard";
export const GET_ACCOUNT_TOGGLE_FOLLOW = "/api/Account/ToggleFollows";
export const GET_ACCOUNT_GENERATE_RANDOM_USERNAME =
  "/api/Account/GenerateRandomUsernames";
export const POST_ACCOUNT_VERIFICATION_FORM = "/api/Account/VerificationForm";
export const GET_ACCOUNT_TOGGLE_FAVORITES = "/api/Account/ToggleFavorites";
export const GET_ACCOUNT_TOGGLE_FAVORITES_COLLECTION =
  "/api/Account/ToggleFavoriteCollection";
export const POST_ACCOUNT_REPORT_NFT = "/api/Account/ReportNft";
export const POST_ACCOUNT_CREATE_COLLECTION = "/api/Account/CreateCollection";
export const POST_ACCOUNT_EDIT_COLLECTION = "/api/Account/EditCollection";
export const GET_ACCOUNT_DELETE_COLLECTION = "/api/Account/DeleteCollection";
export const POST_ACCOUNT_ADD_NFT_COLLECTION = "/api/Account/AddNftCollection";
export const GET_ACCOUNT_DELETE_NFT_COLLECTION =
  "/api/Account/DeleteNftCollection";
export const POST_ACCOUNT_INCREASE_COLLECTION_VIDEO_VIEW =
  "/api/Account/IncreaseCollectionVideoViewCount";
export const GET_ACCOUNT_SYNC_ENGAGEMENT_LEVEL =
  "/api/Account/SyncEngagementLevel";
export const POST_ACCOUNT_REGISTER_FORM_LINK = "/api/Account/RegisterFromLink";
export const GET_ACCOUNT_INSTAGRAM = "/api/Account/UserInstagram";
export const GET_ACCOUNT_MY_TRANSACTION = "/api/Account/GetMyTransaction";
export const GET_ACCOUNT_COLLECTION_NFTS = "/api/Account/GetCollectionNfts_V2";
export const GET_ACCOUNT_USER_COLLECTIONS = "/api/Account/UserCollections";
export const GET_ACCOUNT_FOLLOWNIGS = "/api/Account/GetFollowings";
export const GET_ACCOUNT_FOLLOWERS = "/api/Account/GetFollowers";
export const GET_ACCOUNT_REFERRALS = "/api/Account/GetReferrals";
export const POST_ACCOUNT_REPORT_USER = "/api/Account/ReportUser";
export const GET_ACCOUNT_PROFILE_SEO = "/api/Account/ProfileSEO";
export const GET_ACCOUNT_COLLECTION_DETAILS_SEO =
  "/api/Account/CollectionDetailsSEO";
export const POST_ACCOUNT_CHECK_USERNAME =
  "/api/Account/CheckAvailableUserName";
export const GET_ACCOUNT_MUSEUM_NFTS = "/api/Nfts/ProfileMuseumNftCard";
export const GET_ACCOUNT_CHECK_FOLLOW_USER = "/api/Account/CheckFollowUser";
export const GET_ACCOUNT_WALLET_BALANCE_USER = "/api/Account/WalletBalance";
export const POST_VERIFY_TWITTER = "/api/Account/verifyTwitter";
export const GET_USER_CERTIFICATE = "/api/Account/CreateUserCertificate";

//ETH wallet
export const POST_ACCOUNT_ADD_ETH_WALLET = "/api/Account/AddETHWallet";

//walletSui
export const POST_ACCOUNT_ADD_SUI_WALLET = "/api/Account/AddSuiWallet";
export const POST_ACCOUNT_MERGE_SUI_WALLET = "/api/Account/MergeSuiWallet";
export const POST_ACCOUNT_GET_USER_REFERRAL_PROMOTION =
  "/api/Account/GetUserReferralPromotion";

//HOME
export const GET_HOME_HERO_SECTION = "/api/Home/Section1";
export const GET_HOME_MINTED_NFT_COUNT = "/api/Home/MintedNftCount";
export const GET_HOME_TRENDING_ARTWORK = "/api/Home/TrendingArtWork";
export const GET_HOME_TOP_CURATORS = "/api/Home/TopCurators";
export const GET_HOME_FEATURED_ARTISTS = "/api/Home/FeaturedArtists";
export const GET_HOME_TOP_PHOTOGRAPHERS = "/api/Home/TopPhotographers";
export const GET_HOME_TOP_ARTISTS = "/api/Home/TopArtists";
export const GET_HOME_EXCLUSIVE_COLLECTIONS = "/api/Home/ExclusiveCollections";
export const GET_HOME_COMMUNITIES = "/api/Home/Communities";
export const GET_HOME_MUSEUM = "/api/Home/Museum";
export const GET_HOME_NEW_LISTING = "/api/Home/NewListing";
export const GET_HOME_TOP_COLLECTORS = "/api/Home/TopCollectors";
export const GET_HOME_RECENTLY_SOLD = "/api/Home/RecentlySold";
export const GET_HOME_TESTIMONIALS = "/api/Home/Testimonials";
export const GET_HOME_HOT_BIDS = "/api/Home/HotBids";
export const GET_HOME_HOT_COLLECTIONS = "/api/Home/HotCollections";
export const POST_HOME_NEWSLETTERS = "/api/Home/NewsLetters";
export const GET_HOME_NEWS = "/api/Home/News";
export const POST_HOME_EXCLUSIVE_COLLECTIONS = "/api/Home/ExclusiveCollections";
export const GET_HOME_MARKET_PLACE_INFO = "/api/Home/MarketplaceInfo";
export const POST_HOME_ALL_CATEGORIES = "/api/Home/AllCategories";

// Museum
export const GET_MUSEUM_HERO_SECTION = "/api/Museum/HeroSection";
export const GET_MUSEUM_AUCTION = "/api/Museum/Auction";
export const GET_MUSEUM_CALENDAR_SECTION = "/api/Museum/CalendarSection";
export const GET_MUSEUM_UPCOMINGS = "/api/Museum/Upcomings";
export const GET_MUSEUM_RELEASED = "/api/Museum/Released";
export const GET_MUSEUM_CURRENTWEEK = "/api/Museum/GetCurrentWeek";

//notification
export const GET_NOTIFICATION_USER_NOTIFICATION_SETTINGS =
  "/api/Notifications/UserNotificationSettings";
export const POST_NOTIFICATION_UPDATE_SETTINGS =
  "/api/Notifications/UpdateSettings";
export const POST_NOTIFICATION_NOTIFICATION_FILTER =
  "/api/Notifications/Filter";
export const GET_NOTIFICATION_MARK_ALL_READ =
  "/api/Notifications/MarkAllReadAjax";

//OpenSeaNfts
export const GET_OPENSEA_COLLECTIONS = "api/OpenSeaNfts/GetCollections";
export const GET_OPENSEA_COLLECTIONS_NFT = "api/OpenSeaNfts/GetCollectionNfts";
export const GET_OPENSEA_NFT = "api/OpenSeaNfts/GetNft";
export const GET_OPENSEA_NFT_EVENTS = "api/OpenSeaNfts/GetNftEvents";
export const GET_OPENSEA_ACCOUNTS = "api/OpenSeaNfts/GetAccount";

//Settings
export const GET_SETTINGS = "/api/Settings/GetSettings";
export const GET_ENUMS = "/api/Settings/GetEnums";
export const GET_CATEGORIES = "/api/Settings/GetCategories";

export const GET_COUNTRIES = "/api/Settings/GetCountries";
export const GET_PROVINCES = "/api/Settings/GetProvinces/CountryId";
export const GET_CITIES = "/api/Settings/GetCities/ProvinceId";

//ShippingAddressNfts
export const POST_SHIPPING_ADDRESS =
  "/api/ShippingAddressNfts/CreateShippingAddress";

export const GET_SHIPPING_ADDRESS_DETAIL =
  "/api/ShippingAddressNfts/GetMyShippingAddressNft/NftId";

//Nfts
export const GET_NFTS_NFT_CARD = "/api/Nfts/NftCard_V2";
export const GET_NFTS_DETAIL = "/api/Nfts/Details";
export const GET_NFTS_DETAIL_STEP2 = "/api/Nfts/DetailsStep2";
export const GET_NFTS_INSPIRE = "/api/Nfts/Inspire";
export const GET_NFTS_EXPLORE = "/api/Nfts/Explore";
export const GET_NFTS_TOGGLE_ARCHIVE = "/api/Nfts/ToggleArchive";
export const GET_NFTS_DELETE_NFT = "/api/Nfts/Delete";
export const POST_NFTS_UPLOAD = "/api/Nfts/Upload";
export const POST_NFTS_TOGGLE_LOCK = "/api/Nfts/ToggleLock";
export const POST_NFTS_RELOAD_SYNC_TRANSACTION =
  "/api/Nfts/ReloadSyncContractTransaction";
export const GET_NFTS_CHECK_LOCK = "/api/Nfts/CheckLock";
export const POST_NFTS_BURN_NFT = "/api/Nfts/BurnNft";
export const POST_NFTS_TRANSFER_NFT = "/api/Nfts/TransferNft";
export const POST_NFTS_BRIDGE_NFT = "/api/Nfts/BridgeNft";
export const GET_NFTS_UPDATE_MARKET_PLACE = "/api/Nfts/UpdateMarketPlaceId";
export const POST_NFTS_LIST_NFT = "/api/Nfts/List";
export const POST_NFTS_UNLIST_NFT = "/api/Nfts/UnList";
export const POST_NFTS_FINISH_AUCTION = "/api/Nfts/FinishAuction";
export const POST_NFTS_BUY_NFT = "/api/Nfts/Buy";
export const POST_NFTS_BID_NFT = "/api/Nfts/Bid";
export const GET_NFTS_NFTCID = "/api/Nfts/NFTCID";
export const POST_NFTS_LOG_MINT_NFT = "/api/Nfts/LogMintNft";
export const GET_NFTS_DOWNLOAD_CERTIFICATE = "/api/Nfts/DownloadCertificate";
export const GET_NFTS_CANCEL_PROMOTION = "/api/Nfts/CancelPromotion";
export const GET_NFTS_GENERATE_SLUG_FOR_OLD_NFT =
  "/api/Nfts/GenerateSlugForOldNft";
export const POST_NFTS_AI_DESCRIPTION = "/api/Nfts/AiDescription";
export const POST_NFTS_AI_DESCRIPTION_SHORTEN =
  "/api/Nfts/AiDescription_Shorten";
export const GET_NFTS_ORDER_CONFIRMATION = "/api/Nfts/OrderConfirmation";
export const GET_NFTS_NSFW = "/api/Nfts/NSFW";
export const POST_NFTS_GET_NFT_HISTORY = "/api/Nfts/GetHistoryNft";
export const POST_NFTS_EDIT = "/api/Nfts/Edit";
export const GET_NFTS_DETAILS_SEO = "/api/Nfts/DetailsSEO";
export const GET_NFTS_INSPIRE_CATEGORY_SEO = "/api/Nfts/InspireCategorySEO";
export const POST_NFT_RATING = "/api/Nfts/Rating";
export const GET_NFT_CHECK_DETAILS = "/api/Nfts/CheckNftDetails";
export const GET_NFTS_CHECK_NAME = "/api/Nfts/CheckDuplicateName";

// Tutorials
export const GET_TUTORIALS_LIST = "/api/Tutorials/GetTutorials";
export const POST_TUTORIALS_INCREASE_VIEW_COUNT =
  "/api/Tutorials/IncreaseViewCount";

//collection
export const GET_USER_COLLECTION_NFTS = "/api/Account/UserCollectionDetails";

//GeneralSearch
export const POST_GENERAL_SEARCH = "/api/Searches/GeneralSearch";

//landingPage
export const GET_LANDING_PAGE_WALLET_DATA = "/api/LandingPage/GetWalletData";

export const GET_USER_SEED_TOKEN_BALANCE = "/api/LandingPage/BalanceSeedToken";

//?------------------------------------------------------------?//
//*-----------------------------------------------------------*//
//! ------ PLEASE PUT ADMIN PANEL APIS ABOVE THIS LINE, THANKS ------ !//

//TwitterFarm

//point
export const GET_TWITTER_FARM_GET_MY_POINTS = "/Points/GetMyPoints";
export const POST_TWITTER_FARM_GET_MY_ACTIVITIES = "/Points/GetMyActivities";
export const POST_TWITTER_FARM_GET_MY_POINTS = "/Points/GetUsersPoints";
export const POST_TWITTER_FARM_GET_MY_SEED = "/Points/GetMySeedActivities";
export const POST_TWITTER_FARM_ALL_USER_POINTS = "/Points/AllUserPoints";
export const POST_TWITTER_FARM_ALL_USER_LIKE_POINTS_V2 =
  "/Points/AllUserLikePoints_V2";
export const GET_TWITTER_FARM_UPDATE_USERS_LEAGUE = "/Points/UpdateUsersLeague";
export const POST_TWITTER_FARM_INVITED = "/Points/Invited";

//Withdrawals
export const POST_GET_MY_WITHDRAWALS = "/Withdrawals/GetMyWithdrawals";
export const POST_REQUEST_WITHDRAWALS = "/Withdrawals/RequestWithdrawal";
export const POST_CANCEL_WITHDRAWALS = "/Withdrawals/CancelWithdrawal";

//Stakes
export const POST_GET_MY_STAKES = "/Stakes/GetMyStakes";
export const POST_REQUEST_STAKES = "/Stakes/RequestStakeWithdrawal";
export const POST_CANCEL_STAKES = "/Stakes/CancelStakeWithdrawal";

//custom Task
export const POST_CUSTOM_TASK_LIST = "/CustomTasks/GetCustomTasks";
export const POST_MY_CUSTOM_TASK_LIST = "/CustomTasks/GetMyCustomTasks";
export const POST_TASK_UPDATE = "/CustomTasks/UploadTask";
