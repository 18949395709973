import Image from "next/image";
import React, { useEffect } from "react";
import heroLight from "@/assets/images/home/hero/hero-light.webp";
import heroDark from "@/assets/images/home/hero/hero-dark.webp";

function HeroSection({ homeStyle }) {
  useEffect(() => {
    // Remove all elements with __idm_id__ attribute
    const elements = document.querySelectorAll("[__idm_id__]");
    if (elements) {
      elements.forEach((el) => el.removeAttribute("__idm_id__"));
    }
  }, []);
  return (
    <>
      <div className="center">
        <div className={homeStyle["home__hero"]}>
          <div className={homeStyle["home__hero__section"]}>
            <div className={homeStyle["home__hero__title"]}>
              Discover Our Multifaceted&nbsp;
              <span style={{ color: "#8e793e" }}>Ecosystem </span>
            </div>
            <div className={homeStyle["home__hero__text"]}>
              This landing page offers a comprehensive overview of our diverse
              products and platforms, inviting users to explore, engage, and
              become active participants in our thriving ecosystem.
            </div>
          </div>
          <div className={homeStyle["home__hero__section-img"]}>
            <div className="some-icon-dark" suppressHydrationWarning>
              <video
                suppressHydrationWarning
                className={homeStyle["home__hero__img"]}
                src={`/images/hero/01-Dark-HQ.mp4`}
                width="738"
                height="556"
                autoPlay
                loop
                muted
                controlsList="nodownload"
                poster={heroDark.src}
                preload="none"
              >
                {/* <source src={`/images/hero/01-Dark-HQ.mp4`} type="video/mp4" /> */}
              </video>
            </div>
            <div className="some-icon" suppressHydrationWarning>
              <video
                suppressHydrationWarning
                className={homeStyle["home__hero__img"]}
                src={`/images/hero/01-Light-HQ.mp4`}
                width="738"
                height="556"
                autoPlay
                loop
                muted
                controlsList="nodownload"
                poster={heroLight.src}
                preload="none"
              >
                {/* <source src={`/images/hero/01-Dark-HQ.mp4`} type="video/mp4" /> */}
              </video>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
