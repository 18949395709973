import { useState } from "react";
//Image Steps
import Museum_step1_dark from "@/assets/images/Museum/Museum_step1-dark.gif";
import Museum_step1 from "@/assets/images/Museum/Museum_step1.gif";
import Museum_step2_dark from "@/assets/images/Museum/Museum_step2-dark.gif";
import Museum_step2 from "@/assets/images/Museum/Museum_step2.gif";
import Museum_step3_dark from "@/assets/images/Museum/Museum_step3-dark.gif";
import Museum_step3 from "@/assets/images/Museum/Museum_step3.gif";
import styles from "@/assets/styles/components/_museum.module.scss";
import BurstPuckerImageTitles from "@/components/common/burstPuckerImageTitles";
import Link from "next/link";
import WhatIsMuseumSection from "../../museum/whatIsMuseumSection";

function MuseumSection({ homeStyle }) {
  const [activeStep, setActiveStep] = useState(1);

  const navigateToFormStep = (stepNumber) => {
    setActiveStep(stepNumber);
  };

  const getImageSource = () => {
    switch (activeStep) {
      case 2:
        return Museum_step2;
      case 3:
        return Museum_step3;
      default:
        return Museum_step1;
    }
  };

  const getImageSourceDark = () => {
    switch (activeStep) {
      case 2:
        return Museum_step2_dark;
      case 3:
        return Museum_step3_dark;
      default:
        return Museum_step1_dark;
    }
  };
  return (
    <>
      <div className="center">
        <div className={homeStyle["home__nftMerketplace_head"]}>
          <div className={`burst ${homeStyle["home__nftMerketplace__title"]} `}>
            <BurstPuckerImageTitles /> Museum: Preserve, Profit, Prosper
          </div>

          <div className={homeStyle["home__nftMerketplace__btnExploreforPC"]}>
            <Link className="button-stroke" href="./museum">
              Explore More
              <i className="icon-chevron_up"></i>
            </Link>
          </div>
        </div>

        <WhatIsMuseumSection styles={styles} />

        <div className={homeStyle["home__nftMerketplace"]}>
          <Link
            className={`button-stroke 
              ${homeStyle["home__twitterCommunity__tablet"]} `}
            href="./museum"
          >
            Explore More
            <i className="icon-chevron_up"></i>
          </Link>
        </div>
      </div>
    </>
  );
}

export default MuseumSection;
