import BurstPuckerImageTitles from "@/components/common/burstPuckerImageTitles";
import { numberWithCommas } from "@/components/common/utiles";
import { selecNftMarketPlaceSectionState } from "@/config/helpers/createReduxSelectors";
import CirculatingSupply from "@/public/images/token-metrics/Circulating-Supply-Seed.webp";
import FullyDilutedMarketCap from "@/public/images/token-metrics/Fully-Diluted-Market-Cap_Seed.webp";
import LiveHolders from "@/public/images/token-metrics/Live-Holders-seed.webp";
import MaxTotalSupply from "@/public/images/token-metrics/Max-Total-Supply-Seed.webp";
import Image from "next/image";
import Link from "next/link";
import { useSelector } from "react-redux";

function TokenMetrics({ homeStyle }) {
  const {
    errorMsgMarketPlaceInfo,
    loadingMarketPlaceInfo,
    errorMarketPlaceInfo,
    successMarketPlaceInfo,
    MarketPlaceInfo,
  } = useSelector(selecNftMarketPlaceSectionState);
  return (
    <>
      <div className="center">
        <div className={homeStyle["home__seedToken_metrics"]}>
          <div
            className={`burst h3  ${homeStyle["is-center"]} ${homeStyle["home__nftMerketplace__title"]} `}
          >
            <BurstPuckerImageTitles />
            Token Metrics
            <span style={{ marginLeft: "20px" }}></span>
            <BurstPuckerImageTitles />
          </div>
          <div className={homeStyle["box_holder"]}>
            <div className={homeStyle["metric_box"]}>
              <div
                className={` ${homeStyle["center_content"]} ${homeStyle["metric_box_inner"]}`}
              >
                <Image
                  src={LiveHolders}
                  alt="Live-Holders-seed"
                  width={115}
                  height={134}
                  quality={95}
                />
                <div className={homeStyle["metrics-info"]}>
                  <div className={homeStyle["metrics-title"]}>Live Holders</div>
                  <div className={homeStyle["metric_number"]}>
                    {numberWithCommas(205199)}
                  </div>
                </div>
              </div>
            </div>

            <div className={homeStyle["metric_box"]}>
              <div
                className={` ${homeStyle["metric_box_inner"]} ${homeStyle["center_content"]}`}
              >
                <Image
                  src={MaxTotalSupply}
                  alt="Live-Holders-seed"
                  width={115}
                  height={134}
                  quality={95}
                />
                <div className={homeStyle["metrics-info"]}>
                  <div className={homeStyle["metrics-title"]}>
                    Max Total Supply
                  </div>
                  <div className={homeStyle["metric_number"]}>
                    {MarketPlaceInfo &&
                      numberWithCommas(MarketPlaceInfo?.MaxTotalSupply)}
                  </div>
                </div>
              </div>
            </div>

            <div className={homeStyle["metric_box"]}>
              <div
                className={` ${homeStyle["metric_box_inner"]} ${homeStyle["center_content"]}`}
              >
                <Image
                  src={FullyDilutedMarketCap}
                  alt="Live-Holders-seed"
                  width={115}
                  height={134}
                  quality={95}
                />
                <div className={homeStyle["metrics-info"]}>
                  <div className={homeStyle["metrics-title"]}>
                    Fully Diluted Market Cap
                  </div>
                  <div className={homeStyle["metric_number"]}>
                    {MarketPlaceInfo &&
                      numberWithCommas(MarketPlaceInfo?.FullyDilutedMarketCap)}
                  </div>
                </div>
              </div>
            </div>

            <div className={homeStyle["metric_box"]}>
              <div
                className={` ${homeStyle["metric_box_inner"]} ${homeStyle["center_content"]}`}
              >
                <Image
                  src={CirculatingSupply}
                  alt="Live-Holders-seed"
                  width={115}
                  height={134}
                  quality={95}
                />
                <div className={homeStyle["metrics-info"]}>
                  <div className={homeStyle["metrics-title"]}>
                    Circulating Supply
                  </div>
                  <div className={homeStyle["metric_number"]}>
                    {MarketPlaceInfo && numberWithCommas(275617954)}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={homeStyle["home__nftMerketplace"]}>
            <Link
              className={`button-stroke ${homeStyle["home__twitterCommunity__tablet"]}`}
              href="/"
            >
              Explore More
              <i className="icon-chevron_up"></i>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default TokenMetrics;
