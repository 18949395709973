import defaultAvatar from "@/assets/images/default-user-avatar.gif";
import default_nft_img from "@/assets/images/default_image_01.png";
import { EnumRatioType, s3FolderTypes } from "@/components/constants/layout";
import Image from "next/image";
import Link from "next/link";
import { useState } from "react";
import cloudFlareCdnCgiLoader from "../../cloudFlareCdnCgiLoader";
import { handleMouseEvents } from "../../utiles";

function MinimalCardInnerDetailsByLink({
  item,
  component,
  isSlide,
  classNamecard,
  showProfile = true,
}) {
  const [loaded, setLoaded] = useState(false);

  // Get elements
  var cards;
  var creators;
  var card_buttons;
  var actions;

  if (typeof window !== "undefined") {
    cards = document.querySelectorAll(".item_card");
    creators = document.querySelectorAll(".card2__creator");
    card_buttons = document.querySelectorAll(".card2__action_button");
    actions = document.querySelectorAll(".item-action");
  }

  // function img() {
  //   let startSellFrom = new Date(item.Nft.StartSellFrom);
  //   if (item?.Nft?.StartSellFrom !== null) {
  //     if (startSellFrom.getTime() <= Date.now()) {
  //       return (
  //         <>
  //           <Image
  //             loader={cloudFlareCdnCgiLoader}
  //             src={`${process.env.NEXT_PUBLIC_S3_HOST_NAME}/${s3FolderTypes.S3NftFolder}/${item.Nft.ImageUrl}`}
  //             width={item.Nft.RatioType === EnumRatioType.Portrait ? 350 : 500}
  //             quality={75}
  //             height={325}
  //             alt={`Nft-Musesum`}
  //             key={item.Nft.Id}
  //             style={{
  //               objectFit: "cover",
  //             }}
  //             className={loaded ? "unblur" : "blacks"}
  //             onLoad={() => setLoaded(true)}
  //             placeholder="blur"
  //             blurDataURL={`${
  //               process.env.NEXT_PUBLIC_MEDIA_BASE_URL
  //             }${cloudFlareCdnCgi(350, 1, 100)}${
  //               process.env.NEXT_PUBLIC_S3_HOST_NAME
  //             }/${s3FolderTypes.S3NftFolder}/${item.Nft.ImageUrl}`}
  //           />
  //         </>
  //       );
  //     } else if (
  //       startSellFrom.getTime() > Date.now() &&
  //       startSellFrom.getTime() < Date.now() + 7 * 24 * 60 * 60 * 1000
  //     ) {
  //       return (
  //         <>
  //           <Image
  //             src={`${process.env.NEXT_PUBLIC_MEDIA_BASE_URL}${cloudFlareCdnCgi(
  //               400,
  //               75,
  //               90
  //             )}${process.env.NEXT_PUBLIC_S3_HOST_NAME}/${
  //               s3FolderTypes.S3NftFolder
  //             }/${item.Nft.ImageUrl}`}
  //             key={item.Nft.Id}
  //             width={item.Nft.RatioType === EnumRatioType.Portrait ? 350 : 500}
  //             quality={75}
  //             height={325}
  //             // loading="lazy"
  //             alt={`Nft-Musesum`}
  //             onLoad={() => setLoaded(true)}
  //             placeholder="blur"
  //             blurDataURL={`${
  //               process.env.NEXT_PUBLIC_MEDIA_BASE_URL
  //             }${cloudFlareCdnCgi(350, 1, 100)}${
  //               process.env.NEXT_PUBLIC_S3_HOST_NAME
  //             }/${s3FolderTypes.S3NftFolder}/${item.Nft.ImageUrl}`}
  //           />
  //         </>
  //       );
  //     } else if (
  //       startSellFrom.getTime() >
  //       Date.now() + 7 * 24 * 60 * 60 * 1000
  //     ) {
  //       return (
  //         <>
  //           <Image
  //             src={not_released}
  //             alt="not-released"
  //             width={400}
  //             key={item.Nft.Id}
  //             className="some-icon"
  //             height={400}
  //           />

  //           <Image
  //             src={not_releasedDark}
  //             alt="not-released"
  //             width={400}
  //             height={400}
  //             className="some-icon-dark"
  //           />
  //         </>
  //       );
  //     }
  //   } else {
  //     return (
  //       <>
  //         <Image
  //           loader={cloudFlareCdnCgiLoader}
  //           src={`${process.env.NEXT_PUBLIC_S3_HOST_NAME}/${s3FolderTypes.S3NftFolder}/${item.Nft.ImageUrl}`}
  //           width={item.Nft.RatioType === EnumRatioType.Portrait ? 350 : 500}
  //           quality={75}
  //           height={300}
  //           // loading="lazy"
  //           alt={`Nft-Musesum`}
  //           key={item.Nft.Id}
  //           style={{
  //             objectFit: "cover",
  //           }}
  //           className={loaded ? "unblur" : "blacks"}
  //           onLoad={() => setLoaded(true)}
  //           placeholder="blur"
  //           blurDataURL={`${
  //             process.env.NEXT_PUBLIC_MEDIA_BASE_URL
  //           }${cloudFlareCdnCgi(350, 1, 100)}${
  //             process.env.NEXT_PUBLIC_S3_HOST_NAME
  //           }/${s3FolderTypes.S3NftFolder}/${item.Nft.ImageUrl}`}
  //         />
  //       </>
  //     );
  //   }
  // }

  function img() {
    // let startSellFrom = new Date(item.Nft.StartSellFrom);
    // const dateObject = new Date(item.Nft.StartSellFrom);
    // const timestamp = dateObject.getTime();

    return (
      <>
        <Image
          loader={cloudFlareCdnCgiLoader}
          src={`${process.env.NEXT_PUBLIC_S3_HOST_NAME}/${s3FolderTypes.S3NftFolder}/${item.Nft.ImageUrl}`}
          width={400}
          quality={75}
          height={300}
          loading="lazy"
          alt={`Nft-${item.Nft.Name}`}
          key={item.Nft.Id}
          style={{
            objectFit: "cover",
          }} // cover, contain, none
          className={loaded ? "unblur" : "blacks"}
          onLoad={() => setLoaded(true)}
          placeholder="blur"
          blurDataURL={`${process.env.NEXT_PUBLIC_S3_HOST_NAME}/${s3FolderTypes.S3NftFolder}/${item.Nft.ImageUrl}`}
        />
      </>
    );
  }

  return (
    <>
      <div
        className={` item_card card2  ${
          item.Nft.RatioType === EnumRatioType.Portrait
            ? "portrait"
            : "landscape"
        }`}
        style={{ border: "unset" }}
      >
        <Link
          // href={`/nfts/details/${item.Nft.Slug}`}
          href={`${item.Nft.link ? item.Nft.link : "#"}`}
          className="item_card card2__box"
          key={item.Nft.Id}
          target="_blank"
          aria-label="nfts-details"
          // onMouseOver={handleMouseEvents(creators)}
          data-link={`/nfts/details/${item.Nft.Slug}`}
        >
          <div className={`card2__preview ${classNamecard}`}>
            {item?.Nft?.ImageUrl !== null ? (
              item?.Nft?.ImageUrl.endsWith(".mp4") ? (
                <video
                  src={`${process.env.NEXT_PUBLIC_S3_HOST_NAME}/${s3FolderTypes.S3NftMediaFolder}/${item.Nft.ImageUrl}`}
                ></video>
              ) : (
                img()
              )
            ) : (
              <Image src={default_nft_img} alt="Card preview" />
            )}
            {showProfile &&
              item.Ownerships &&
              (item.Ownerships.length === 1 ? (
                item.Ownerships.map((owners, index) => (
                  <button
                    className="card2__creator"
                    data-link={`/artist/${owners.UserName}`}
                    key={index}
                    onMouseOver={handleMouseEvents(creators, true)}
                  >
                    {owners.ProfileImage != null ? (
                      <div className="card2__avatars">
                        <Image
                          loader={cloudFlareCdnCgiLoader}
                          src={`${process.env.NEXT_PUBLIC_S3_HOST_NAME}/${s3FolderTypes.S3ProfileFolder}/${owners.ProfileImage}`}
                          loading="lazy"
                          alt="user-profile"
                          width={200}
                          quality={80}
                          height={200}
                          style={{
                            objectFit: "cover",
                            height: "24",
                            width: "24",
                          }}
                        />
                      </div>
                    ) : (
                      <div className="card2__avatars">
                        <Image src={defaultAvatar} alt="Seed default avatar" />
                      </div>
                    )}
                    <span className="creator__name">
                      {owners.DisplayName
                        ? owners.DisplayName
                        : owners.FirstName && owners.LastName
                        ? `${owners.FirstName} ${owners.LastName}`
                        : owners.UserName}
                    </span>
                  </button>
                ))
              ) : (
                <div>
                  <div className="card2__creator multi_owner">
                    {item.Ownerships.map((owners, index) =>
                      owners.ProfileImage != null ? (
                        <div className="card2__avatars" key={index}>
                          <Image
                            loader={cloudFlareCdnCgiLoader}
                            src={`${process.env.NEXT_PUBLIC_S3_HOST_NAME}/${s3FolderTypes.S3ProfileFolder}/${owners.ProfileImage}`}
                            loading="lazy"
                            alt="user-profile"
                            width={200}
                            quality={80}
                            height={200}
                            style={{
                              objectFit: "cover",
                              height: "24",
                              width: "24",
                            }}
                          />
                        </div>
                      ) : (
                        <div className="card2__avatars" key={index}>
                          <Image src={defaultAvatar} alt="defaultAvatar" />
                        </div>
                      )
                    )}
                    <span className="creator__name">Multiple Owners</span>
                  </div>
                  <div className="card2__creator_list">
                    {item?.Ownerships?.map((owners) => {
                      let owner_name = "";
                      if (owners.DisplayName != null) {
                        owner_name = owners.DisplayName;
                      } else if (
                        owners.FirstName != null &&
                        owners.LastName != null
                      ) {
                        owner_name = `${owners.FirstName} ${owners.LastName}`;
                      } else {
                        owner_name = owners.UserName;
                      }

                      return (
                        <button
                          className="card2__creator"
                          data-link={`/artist/${owners.UserName}`}
                          //  data-link={`${baseHostUrl}/artist/${owners.UserName}`}
                          key={owners.Id} // Add a unique key for each button
                          onMouseOver={handleMouseEvents(creators, true)}
                        >
                          {owners.ProfileImage != null ? (
                            <div className="card2__avatars">
                              <Image
                                loader={cloudFlareCdnCgiLoader}
                                src={`${process.env.NEXT_PUBLIC_S3_HOST_NAME}/${s3FolderTypes.S3ProfileFolder}/${owners.ProfileImage}`}
                                loading="lazy"
                                alt="user-profile"
                                width={200}
                                quality={80}
                                height={200}
                                style={{
                                  objectFit: "cover",
                                  height: "24",
                                  width: "24",
                                }}
                              />
                            </div>
                          ) : (
                            <div className="card2__avatars">
                              <Image src={defaultAvatar} alt="defaultAvatar" />
                            </div>
                          )}
                          <span className="creator__name">{owner_name}</span>
                        </button>
                      );
                    })}
                  </div>{" "}
                </div>
              ))}
            {}
          </div>
        </Link>
      </div>
    </>
  );
}

export default MinimalCardInnerDetailsByLink;
