import BurstPuckerImageTitles from "@/components/common/burstPuckerImageTitles";
import Link from "next/link";

import AwardsCards from "./components/AwardsCards";
import AwardsGallery from "./components/AwardsGallery";
import AwardsGrantedLogos from "./components/AwardsGrantedLogos";
import AwardsPhotosHero from "./components/AwardsPhotosHero";
import ImageHighwaySwiper from "./components/ImageHighwaySwiper";

function AwardsPhotoSections({ homeStyle }) {
  return (
    <>
      <div className="center">
        <div className={homeStyle["home__nftMerketplace_head"]}>
          <div
            className={`burst ${homeStyle["home__nftMerketplace__title"]} 
          ${homeStyle["home__awardsPtotos__color"]} `}
          >
            <BurstPuckerImageTitles /> AWARDS.PHOTOS
          </div>

          <div className={homeStyle["home__nftMerketplace__btnExploreforPC"]}>
            <Link
              className={`button-stroke ${homeStyle["home__awardsPtotos__color"]} `}
              href="https://awards.photos/"
              target="_blank"
            >
              Explore More
              <i className="icon-chevron_up"></i>
            </Link>
          </div>
        </div>

        <AwardsPhotosHero homeStyle={homeStyle} />

        <AwardsCards homeStyle={homeStyle} />
      </div>

      <div className={homeStyle["highway"]}>
        <ImageHighwaySwiper homeStyle={homeStyle} />
      </div>

      <div className="center">
        <AwardsGrantedLogos homeStyle={homeStyle} />

        <AwardsGallery homeStyle={homeStyle} />
      </div>

      <div className={homeStyle["home__nftMerketplace"]}>
        <Link
          className={`button-stroke  ${homeStyle["home__twitterCommunity__tablet"]} 
          ${homeStyle["home__awardsPtotos__color"]}`}
          href="https://awards.photos/"
          target="_blank"
        >
          Explore More
          <i className="icon-chevron_up"></i>
        </Link>
      </div>
    </>
  );
}

export default AwardsPhotoSections;
