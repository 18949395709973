import BurstPuckerImageTitles from "@/components/common/burstPuckerImageTitles";

import seedToken from "@/assets/images/home/SeedToken/seedToken.webp";
import seedToken2 from "@/assets/images/home/SeedToken/seedToken-2.png";

import seedtoken11 from "@/assets/images/home/SeedToken/seedtoken1-1.svg";
import seedtoken12 from "@/assets/images/home/SeedToken/seedtoken1-2.svg";
import seedtoken13 from "@/assets/images/home/SeedToken/seedtoken1-3.svg";
import seedtoken14 from "@/assets/images/home/SeedToken/seedtoken1-4.svg";
import seedtoken15 from "@/assets/images/home/SeedToken/seedtoken1-5.svg";

import seedtoken21 from "@/assets/images/home/SeedToken/seedtoken2-1.svg";
import seedtoken22 from "@/assets/images/home/SeedToken/seedtoken2-2.svg";
import seedtoken23 from "@/assets/images/home/SeedToken/seedtoken2-3.svg";
import seedtoken24 from "@/assets/images/home/SeedToken/seedtoken2-4.svg";
import seedtoken25 from "@/assets/images/home/SeedToken/seedtoken2-5.svg";
import seedtoken26 from "@/assets/images/home/SeedToken/seedtoken2-6.svg";
import seedtoken27 from "@/assets/images/home/SeedToken/seedtoken2-7.svg";
import seedtoken28 from "@/assets/images/home/SeedToken/seedtoken2-8.svg";
import seedtoken29 from "@/assets/images/home/SeedToken/seedtoken2-9.svg";
import seedtoken210 from "@/assets/images/home/SeedToken/seedtoken2-10.svg";

import seedtoken3 from "@/assets/images/home/SeedToken/seedtoken3.svg";
import seedtoken31dark from "@/assets/images/home/SeedToken/seedtoken3-1-dark.svg";
import seedtoken31light from "@/assets/images/home/SeedToken/seedtoken3-1-light.svg";

import seedToken4Dark from "@/assets/images/home/SeedToken/seedtoken4-dark.gif";
import seedToken4Light from "@/assets/images/home/SeedToken/seedToken4-light.gif";
import Image from "next/image";
import Link from "next/link";
import { useMediaQuery } from "react-responsive";
import { useEffect } from "react";
import styles from "@/assets/styles/pages/_twitter_farm.module.scss";

function SeedTokenSection({ homeStyle }) {
  const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });

  useEffect(() => {
    const lazyVideos = Array.from(document.querySelectorAll("video.lazy"));

    if ("IntersectionObserver" in window) {
      const lazyVideoObserver = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach((video) => {
            if (video.isIntersecting) {
              for (let source in video.target.children) {
                let videoSource = video.target.children[source];
                if (
                  typeof videoSource.tagName === "string" &&
                  videoSource.tagName === "SOURCE"
                ) {
                  videoSource.src = videoSource.dataset.src;
                }
              }

              video.target.load();
              video.target.classList.remove("lazy");
              video.target.parentElement.classList.remove("skeleton-video");
              lazyVideoObserver.unobserve(video.target);
            }
          });
        }
      );

      lazyVideos.forEach((lazyVideo) => {
        lazyVideoObserver.observe(lazyVideo);
      });
    }
  }, []);

  return (
    <>
      <div className="center">
        <div className={homeStyle["home__nftMerketplace_head"]}>
          <div
            className={`burst h3 ${homeStyle["home__nftMerketplace__title"]} 
          ${homeStyle["home__seedToken__title"]}`}
          >
            <BurstPuckerImageTitles />
            SEED Token
          </div>

          <div className={homeStyle["home__nftMerketplace__btnExploreforPC"]}>
            <Link
              className="button-stroke"
              href="https://token.seed.photo/"
              target="_blank"
            >
              Explore More
              <i className="icon-chevron_up"></i>
            </Link>
          </div>
        </div>

        <div className={homeStyle["home__seedToken"]}>
          <div className={homeStyle["home__seedToken__section1"]}>
            <div className={homeStyle["home__seedToken__text2"]}>
              Discover the SEED.Photo token, our digital currency that supports
              a new era of creative freedom and collaboration. Experience the
              power of blockchain technology with easy transactions, rewards,
              and community engagement.
            </div>

            {isMobile && (
              <div className={homeStyle["home__seedToken__img2"]}>
                <div className="some-icon">
                  <video
                    className={styles["tw_farm__hero__img"]}
                    src={`/images/hero/seedTokenLight.mp4`}
                    poster={seedToken.src}
                    width="900"
                    height="675"
                    autoPlay
                    loop={true}
                    muted
                    preload="none"
                    controlsList="nodownload lazy"
                  >
                    {/* <source src={`/images/hero/01-Dark-HQ.mp4`} type="video/mp4" /> */}
                  </video>
                </div>
                <div className="some-icon-dark">
                  <video
                    className={styles["tw_farm__hero__img"]}
                    src={`/images/hero/seedtokenDark.mp4`}
                    poster={seedToken.src}
                    width="900"
                    height="675"
                    autoPlay
                    loop={true}
                    muted
                    preload="none"
                    controlsList="nodownload lazy"
                  >
                    {/* <source src={`/images/hero/01-Dark-HQ.mp4`} type="video/mp4" /> */}
                  </video>
                </div>
              </div>
            )}

            <div className={homeStyle["home__seedToken__items"]}>
              <Link
                className={homeStyle["home__seedToken__item"]}
                href="https://skynet.certik.com/projects/seed-photo"
                target="_blank"
              >
                <div className={homeStyle["home__seedToken__icons"]}>
                  <div className={homeStyle["icon_holder"]}>
                    <Image
                      src={seedtoken11}
                      className={homeStyle["home__seedToken__icon"]}
                      width={24}
                      height={24}
                      alt="seedtoken11"
                    />
                  </div>
                  <div className={homeStyle["icon_holder"]}>
                    <Image
                      src={seedtoken12}
                      className={homeStyle["home__seedToken__icon"]}
                      width={24}
                      height={24}
                      alt="seedtoken12"
                    />
                  </div>
                  <div className={homeStyle["icon_holder"]}>
                    <Image
                      src={seedtoken13}
                      className={homeStyle["home__seedToken__icon"]}
                      width={24}
                      height={24}
                      alt="seedtoken13"
                    />
                  </div>
                  <div className={homeStyle["icon_holder"]}>
                    <Image
                      src={seedtoken14}
                      className={homeStyle["home__seedToken__icon"]}
                      width={24}
                      height={24}
                      alt="seedtoken14"
                    />
                  </div>
                  <div className={homeStyle["icon_holder"]}>
                    <Image
                      src={seedtoken15}
                      className={homeStyle["home__seedToken__icon"]}
                      width={24}
                      height={24}
                      alt="seedtoken15"
                    />
                  </div>
                </div>
                <div className={homeStyle["home__seedToken__text"]}>
                  CertiK KYC <span style={{ color: "#8e793e" }}>Gold </span>{" "}
                  Verified
                </div>
              </Link>
              <Link
                className={homeStyle["home__seedToken__item"]}
                href="https://coinmarketcap.com/currencies/seed-photo/"
                target="_blank"
              >
                <div className={homeStyle["home__seedToken__icons"]}>
                  <div className={homeStyle["icon_holder"]}>
                    <Image
                      src={seedtoken21}
                      className={homeStyle["home__seedToken__icon"]}
                      width={24}
                      height={24}
                      alt="seedtoken21"
                    />
                  </div>
                  <div className={homeStyle["icon_holder"]}>
                    <Image
                      src={seedtoken22}
                      className={homeStyle["home__seedToken__icon"]}
                      width={24}
                      height={24}
                      alt="seedtoken22"
                    />
                  </div>
                  <div className={homeStyle["icon_holder"]}>
                    <Image
                      src={seedtoken23}
                      className={homeStyle["home__seedToken__icon"]}
                      width={24}
                      height={24}
                      alt="seedtoken23"
                    />
                  </div>
                  <div className={homeStyle["icon_holder"]}>
                    <Image
                      src={seedtoken24}
                      className={homeStyle["home__seedToken__icon"]}
                      width={24}
                      height={24}
                      alt="seedtoken24"
                    />
                  </div>
                  <div className={homeStyle["icon_holder"]}>
                    <Image
                      src={seedtoken25}
                      className={homeStyle["home__seedToken__icon"]}
                      width={24}
                      height={24}
                      alt="seedtoken25"
                    />
                  </div>
                  <div className={homeStyle["icon_holder"]}>
                    <Image
                      src={seedtoken26}
                      className={homeStyle["home__seedToken__icon"]}
                      width={24}
                      height={24}
                      alt="seedtoken26"
                    />
                  </div>
                  <div className={homeStyle["icon_holder"]}>
                    <Image
                      src={seedtoken27}
                      className={homeStyle["home__seedToken__icon"]}
                      width={24}
                      height={24}
                      alt="seedtoken27"
                    />
                  </div>
                  <div className={homeStyle["icon_holder"]}>
                    <Image
                      src={seedtoken28}
                      className={homeStyle["home__seedToken__icon"]}
                      width={24}
                      height={24}
                      alt="seedtoken28"
                    />
                  </div>
                  <div className={homeStyle["icon_holder"]}>
                    <Image
                      src={seedtoken29}
                      className={homeStyle["home__seedToken__icon"]}
                      width={24}
                      height={24}
                      alt="seedtoken29"
                    />
                  </div>
                  <div className={homeStyle["icon_holder"]}>
                    <Image
                      src={seedtoken210}
                      className={homeStyle["home__seedToken__icon"]}
                      width={24}
                      height={24}
                      alt="seedtoken210"
                    />
                  </div>
                </div>
                <div className={homeStyle["home__seedToken__text"]}>
                  Official Trackers
                </div>
              </Link>
              <Link
                className={homeStyle["home__seedToken__item"]}
                href="https://token.seed.photo/?P=Intro"
                target="_blank"
              >
                <div className={homeStyle["home__seedToken__icons"]}>
                  <div className={homeStyle["icon_holder"]}>
                    <Image
                      src={seedtoken3}
                      className={homeStyle["home__seedToken__icon"]}
                      width={24}
                      height={24}
                      alt="seedtoken3"
                    />
                  </div>

                  <div className={homeStyle["home__hero__dark"]}>
                    <Image src={seedtoken31dark} alt="seedtoken31dark" />
                  </div>
                  <div className={homeStyle["home__hero__light"]}>
                    <Image src={seedtoken31light} alt="seedtoken31light" />
                  </div>
                </div>
                <div className={homeStyle["home__seedToken__text"]}>
                  Utility and Use Cases
                </div>
              </Link>
              <Link
                className={homeStyle["home__seedToken__item"]}
                href="https://token.seed.photo/?P=ecosystem"
                target="_blank"
              >
                <div>
                  <div className={homeStyle["home__hero__dark"]}>
                    <Image
                      src={seedToken4Dark}
                      className={homeStyle["home__seedToken__icon1"]}
                      alt="seedToken4Dark"
                      width={44}
                      height={44}
                    />
                  </div>
                  <div className={homeStyle["home__hero__light"]}>
                    <Image
                      src={seedToken4Light}
                      className={homeStyle["home__seedToken__icon1"]}
                      alt="seedToken4Light"
                      width={44}
                      height={44}
                    />
                  </div>
                </div>
                <div className={homeStyle["home__seedToken__text"]}>
                  Ecosystem Integration
                </div>
              </Link>
            </div>
          </div>
          {!isMobile && (
            <div className={homeStyle["home__seedToken__img"]}>
              <div className="some-icon">
                <video
                  className={styles["tw_farm__hero__img"]}
                  src={`/images/hero/seedTokenLight.mp4`}
                  poster={seedToken.src}
                  width="900"
                  height="675"
                  autoPlay
                  loop={true}
                  muted
                  preload="none"
                  controlsList="nodownload lazy"
                >
                  {/* <source src={`/images/hero/01-Dark-HQ.mp4`} type="video/mp4" /> */}
                </video>
              </div>
              <div className="some-icon-dark">
                <video
                  className={styles["tw_farm__hero__img"]}
                  src={`/images/hero/seedtokenDark.mp4`}
                  poster={seedToken.src}
                  width="900"
                  height="675"
                  autoPlay
                  loop={true}
                  muted
                  preload="none"
                  controlsList="nodownload lazy"
                >
                  {/* <source src={`/images/hero/01-Dark-HQ.mp4`} type="video/mp4" /> */}
                </video>
              </div>
            </div>
          )}
        </div>

        <div className={homeStyle["home__seedToken__img-seedtoken"]}>
          <Image
            src={seedToken2}
            alt="seedToken"
            width={900}
            height={452}
            loading="lazy"
            quality={85}
          />
        </div>
      </div>
    </>
  );
}

export default SeedTokenSection;
