import { createSelector } from "@reduxjs/toolkit";

//marketplace info
const NftMarketPlaceSectionState = (state) => state.HomeMarketPlaceInfo;
export const selecNftMarketPlaceSectionState = createSelector(
  NftMarketPlaceSectionState,
  (state) => ({
    errorMsgMarketPlaceInfo: state.errorMsgMarketPlaceInfo,
    loadingMarketPlaceInfo: state.loadingMarketPlaceInfo,
    errorMarketPlaceInfo: state.errorMarketPlaceInfo,
    successMarketPlaceInfo: state.successMarketPlaceInfo,
    MarketPlaceInfo: state.MarketPlaceInfo,
  })
);

const selectMyProfileState = (state) => state.MyProfile;
export const createSelectorMyProfileData = createSelector(
  selectMyProfileState,
  (state) => ({
    error: state.error,
    success: state.success,
    MyProfileUser: state.MyProfileUser,
    message: state.message,
    loading: state.loading,
  })
);

const selectWalletBalancesState = (state) => state.WalletBalance;
export const createSelectorWalletBalancesData = createSelector(
  selectWalletBalancesState,
  (state) => ({
    error: state.error,
    success: state.success,
    walletBalance: state.balance,
    message: state.message,
    loading: state.loading,
  })
);

const selectMergeWalletSuiState = (state) => state.SuiWalletAccount;
export const selectMergeWalletSuiData = createSelector(
  selectMergeWalletSuiState,
  (state) => ({
    AddSuiWalletSuccess: state.AddSuiWalletSuccess,
    AddSuiWalletError: state.AddSuiWalletError,
    AddSuiWallet: state.AddSuiWallet,
    AddSuiWalletMessage: state.AddSuiWalletMessage,

    MergeSuiWalletError: state.MergeSuiWalletError,
    MergeSuiWalletSuccess: state.MergeSuiWalletSuccess,
    MergeSuiWallet: state.MergeSuiWallet,
    MergeSuiWalletMessage: state.MergeSuiWalletMessage,
    MergeSuiWalletLoading: state.MergeSuiWalletLoading,
  })
);

const selectMergeWalletEthState = (state) => state.EthWalletAccount;
export const selectMergeWalletEthData = createSelector(
  selectMergeWalletEthState,
  (state) => ({
    AddEthWalletError: state.AddEthWalletError,
    AddEthWalletSuccess: state.AddEthWalletSuccess,
    AddEthWalletMessage: state.AddEthWalletMessage,
  })
);

//Profile
const selectProfile = (state) => state.Profile;
export const createSelectorProfile = createSelector(selectProfile, (state) => ({
  UserProfile: state.user,
  //checkFollow
  errorMsgCheckFollow: state.errorMsgCheckFollow,
  loadingCheckFollow: state.loadingCheckFollow,
  errorCheckFollow: state.errorCheckFollow,
  successCheckFollow: state.successCheckFollow,
  checkFollow: state.checkFollow,

  //edit
  edit: state.edit,
  loadingedit: state.loadingedit,
  successedit: state.successedit,
  erroredit: state.erroredit,
  messageedit: state.messageedit,
}));

//follow toggle
const selectFollowToggle = (state) => state.followtoggle;
export const createSelectorFollowToggle = createSelector(
  selectFollowToggle,
  (state) => ({
    errorfollow: state.errorfollow,
    successfollow: state.successfollow,
    loadingfollow: state.loadingfollow,
  })
);

// Setting
const selectGetSettingsState = (state) => state.GetSettings;
export const selectGetSettingsData = createSelector(
  selectGetSettingsState,
  (state) => ({
    successSettings: state.successSettings,
    Settings: state.Settings,
    loadingSettings: state.loadingSettings,
  })
);

//Enum
const selectGetEnumsState = (state) => state.GetEnums;
export const selectGetEnumsData = createSelector(
  selectGetEnumsState,
  (state) => ({
    loadingEnums: state.loadingEnums,
    successEnums: state.successEnums,
    errorEnums: state.errorEnums,
    MessageEnums: state.MessageEnums,
    Enums: state.Enums,
  })
);

//Generate Random Username
const selectGenerateRandomUsernameState = (state) => state.Randomuser;
export const createSelectorGenerateRandomUsername = createSelector(
  selectGenerateRandomUsernameState,
  (state) => ({
    Random: state.Random,
    loadingRandom: state.loadingRandom,
    successRandom: state.successRandom,
    errorRandom: state.errorRandom,
    messageRandom: state.messageRandom,
  })
);

//CheckUserName
const selectCheckUserNameState = (state) => state.CheckUserName;
export const createSelectorCheckUserName = createSelector(
  selectCheckUserNameState,
  (state) => ({
    loadingCheckUserName: state.loadingCheckUserName,
    errorCheckUserName: state.errorCheckUserName,
    successCheckUserName: state.successCheckUserName,
    CheckUserName: state.CheckUserName,
  })
);

const selectEditCoverProfileState = (state) => state.editGallaryCover;
export const createSelectorEditCoverProfile = createSelector(
  selectEditCoverProfileState,
  (state) => ({
    errorgallary: state.errorgallary,
    successGallary: state.successGallary,
    Gallary: state.Gallary,
    messagegallary: state.messagegallary,
    loadinggallary: state.loadinggallary,
  })
);

const selectCoverProfileState = (state) => state.coverprofile;
export const createSelectorCoverProfile = createSelector(
  selectCoverProfileState,
  (state) => ({
    error: state.error,
    success: state.success,
    user: state.user,
    message: state.message,
    loading: state.loading,
  })
);

//VerificationForm
const selectVerificationFormState = (state) => state.VerificationForm;
export const createSelectorVerificationFormData = createSelector(
  selectVerificationFormState,
  (state) => ({
    loadingVerification: state.loadingVerification,
    successVerification: state.successVerification,
    errorMsgVerification: state.errorMsgVerification,
    errorVerification: state.errorVerification,
    data: state.data,
  })
);

//Tutorials
const selectTutorialsState = (state) => state.Tutorials;
export const createSelectorTutorials = createSelector(
  selectTutorialsState,
  (state) => ({
    errorMsgTutorials: state.errorMsgTutorials,
    loadingTutorials: state.loadingTutorials,
    errorTutorials: state.errorTutorials,
    successTutorials: state.successTutorials,
    data: state.data,

    increaseSuccess: state.increaseSuccess,
    increaseError: state.increaseError,
    increaseData: state.increaseData,
    increaseLoading: state.increaseLoading,

    data2: state.data2,
    loadingTutorials2: state.loadingTutorials2,
    errorTutorials2: state.errorTutorials2,
    successTutorials2: state.successTutorials2,
    errorMsgTutorials2: state.errorMsgTutorials2,
  })
);

//Upload Nft
const selectUploadNftState = (state) => state.UploadNft;
export const createSelectorUploadNft = createSelector(
  selectUploadNftState,
  (state) => ({
    errorUploadNft: state.errorUploadNft,
    successUploadNft: state.successUploadNft,
    errorMsgUploadNft: state.errorMsgUploadNft,
    form: state.form,
    loadingUploadNft: state.loadingUploadNft,
    //Referral Promotion
    userPromotionSuccess: state.userPromotionSuccess,
    userPromotionError: state.userPromotionError,
    userPromotionErrorMsg: state.userPromotionErrorMsg,
    userPromotionLoading: state.userPromotionLoading,
    userPromotion: state.userPromotion,

    //CheckNameNftText
    CheckNameNftText: state.CheckNameNftText,
    errorMsgCheckNameNft: state.errorMsgCheckNameNft,
    successCheckNameNft: state.successCheckNameNft,
    loadingCheckNameNft: state.loadingCheckNameNft,
    errorCheckNameNft: state.errorCheckNameNft,
  })
);

//Generate Ai description
const selectGenerateDescriptionState = (state) => state.UploadNft;
export const createSelectorGenerateDescription = createSelector(
  selectGenerateDescriptionState,
  (state) => ({
    errorGenerate: state.errorMsgNftdescription,
    successGenerate: state.successNftdescription,
    nameGenerate: state.name,
    loadingGenerate: state.loadingNftdescription,
    //short description
    errorShorteren: state.errordescriptionShorteren,
    successShorteren: state.successdescriptionShorteren,
    StoryShorteren: state.story,
    loadingShorteren: state.loadingdescriptionShorteren,
  })
);

//User Collections
const selectUserCollectionsState = (state) => state.UserCollection;
export const createSelectorUserCollections = createSelector(
  selectUserCollectionsState,
  (state) => ({
    errorCollections: state.error,
    successCollections: state.success,
    userCollections: state.user,
    message: state.message,
    loadingCollections: state.loading,
    //Create
    errorCreateCollections: state.errorCreateCollection,
    successCreateCollections: state.successCreateCollection,
    formCreateCollections: state.form,
    loadingCtreateCollections: state.loadingCreateCollection,
    errorMsgCreateCollection: state.errorMsgCreateCollection,
  })
);

// Nft Detail
const selectNftDetailState = (state) => state.NftDetail;
export const createSelectorNftDetail = createSelector(
  selectNftDetailState,
  (state) => ({
    errorMsgNftDetail: state.errorMsgNftDetail,
    loadingNftDetail: state.loadingNftDetail,
    errorNftDetail: state.errorNftDetail,
    successNftDetail: state.successNftDetail,
    info: state.info,
    //statusStep2
    errorMsgNftDetailStep2: state.errorMsgNftDetailStep2,
    loadingNftDetailStep2: state.loadingNftDetailStep2,
    errorNftDetailStep2: state.errorNftDetailStep2,
    successNftDetailStep2: state.successNftDetailStep2,
    infoStep2: state.infoStep2,
    statusStep2: state.statusStep2,
    //check-detail
    errorMsgCheckDetail: state.errorMsgCheckDetail,
    loadingCheckDetail: state.loadingCheckDetail,
    errorCheckDetail: state.errorCheckDetail,
    successCheckDetail: state.successCheckDetail,
    checkDetail: state.checkDetail,
    //cancelPromotion
    errorMsgCancelPromotion: state.errorMsgCancelPromotion,
    loadingCancelPromotion: state.loadingCancelPromotion,
    errorCancelPromotion: state.errorCancelPromotion,
    successCancelPromotion: state.successCancelPromotion,
    cancelPromotion: state.cancelPromotion,
    //archive
    errorMsgArchive: state.errorMsgArchive,
    loadingArchive: state.loadingArchive,
    errorArchive: state.errorArchive,
    successArchive: state.successArchive,
    archive: state.archive,
  })
);

// Edit Detail
const selectEditDetailState = (state) => state.EditNft;
export const createSelectorEditDetail = createSelector(
  selectEditDetailState,
  (state) => ({
    errorMsgEditNft: state.errorMsg,
    loadingEditNft: state.loading,
    errorEditNft: state.error,
    successEditNft: state.success,
    dataEditNft: state.form,
  })
);

// Notification
const selectNotificationState = (state) => state.NotificationFilter;
export const createSelectorNotification = createSelector(
  selectNotificationState,
  (state) => ({
    loadingfilterNotification: state.loadingfilterNotification,
    errorfilterNotification: state.successfilterNotification,
    successfilterNotification: state.errorfilterNotification,
    notifs: state.notifs,
  })
);

// Last Notification
const selectLastNotificationState = (state) => state.LastNotificationFilter;
export const createSelectorLastNotification = createSelector(
  selectLastNotificationState,
  (state) => ({
    errorfilterNotification: state.errorfilterNotification,
    loadingfilterNotification: state.loadingfilterNotification,
    successfilterNotification: state.successfilterNotification,
    notifs: state.notifs,
  })
);

//MarkAllRead
const selectMarkAllReadState = (state) => state.MarkAllRead;
export const createSelectorMarkAllRead = createSelector(
  selectMarkAllReadState,
  (state) => ({
    loadingMarkAllRead: state.loadingMarkAllRead,
    errorMarkAllRead: state.errorMarkAllRead,
    successMarkAllRead: state.successMarkAllRead,
    NotifsVisited: state.NotifsVisited,
  })
);

//events
const selectEventsState = (state) => state.Events;
export const createSelectorEvents = createSelector(
  selectEventsState,
  (state) => ({
    errorMsg: state.errorMsg,
    loading: state.loading,
    error: state.error,
    success: state.success,
    events: state.events,
  })
);

const selectCertificateNftState = (state) => state.DownloadCertificate;
export const createSelectorCertificateNft = createSelector(
  selectCertificateNftState,
  (state) => ({
    loading: state.loading,
    success: state.success,
    error: state.error,
    certificate: state.certificate,
  })
);

const selectCertificateArtistState = (state) => state.ArtistCertificate;
export const createSelectorArtistCertificateNft = createSelector(
  selectCertificateArtistState,
  (state) => ({
    loading: state.loading,
    success: state.success,
    error: state.error,
    certificate: state.certificate,
  })
);

//User Collection Nft
const selectCollectionNftActionState = (state) => state.UserCollectionNft;
export const createSelectorCollectionNftAction = createSelector(
  selectCollectionNftActionState,
  (state) => ({
    errorMsgUserCollectionNft: state.errorMsgUserCollectionNft,
    loadingUserCollectionNft: state.loadingUserCollectionNf,
    errorUserCollectionNft: state.errorUserCollectionNft,
    successUserCollectionNft: state.successUserCollectionNft,
    nft: state.nft,
    //Delete collection
    errorMsgUserCollectionDelete: state.errorMsgUserCollectionDelete,
    loadingUserCollectionDelete: state.loadingUserCollectionDelete,
    errorUserCollectionDelete: state.errorUserCollectionDelete,
    successUserCollectionDelete: state.successUserCollectionDelete,
    //EDIT
    errorMsgUserCollectionEdit: state.errorMsgUserCollectionEdit,
    loadingUserCollectionEdit: state.loadingUserCollectionEdit,
    errorUserCollectionEdit: state.errorUserCollectionEdit,
    successUserCollectionEdit: state.successUserCollectionEdit,
    collectionEdit: state.collectionEdit,

    //Delete nft from collection
    errorMsgUserCollectionDeleteNft: state.errorMsgUserCollectionDeleteNft,
    loadingUserCollectionDeleteNft: state.loadingUserCollectionDeleteNft,
    errorUserCollectionDeleteNft: state.errorUserCollectionDeleteNft,
    successUserCollectionDeleteNft: state.successUserCollectionDeleteNft,
    //add Nft to Colleciton
    loadingAddNftCollection: state.loadingAddNftCollection,
    errorAddNftCollection: state.errorAddNftCollection,
    successAddNftCollection: state.successAddNftCollection,
  })
);

// landingPageWalletData
const selectLandingPageWalletDataState = (state) => state.LandingPageReducer;
export const createSelectorLandingPageWalletData = createSelector(
  selectLandingPageWalletDataState,
  (state) => ({
    loadinglandingPageWalletData: state.loadinglandingPageWalletData,
    successlandingPageWalletData: state.successlandingPageWalletData,
    errorlandingPageWalletData: state.errorlandingPageWalletData,
    landingPageWalletData: state.landingPageWalletData,
    errorlandingPageWalletDataMsg: state.errorlandingPageWalletDataMsg,

    //balanceSeedTokenData
    loadingBalanceSeedTokenData: state.loadingBalanceSeedTokenData,
    successBalanceSeedTokenData: state.successBalanceSeedTokenData,
    errorBalanceSeedTokenData: state.errorBalanceSeedTokenData,
    balanceSeedTokenData: state.balanceSeedTokenData,
    errorBalanceSeedTokenDataMsg: state.errorBalanceSeedTokenDataMsg,
  })
);

//ToggleNftLike
const selectToggleNftLikeState = (state) => state.ToggleNftLike;
export const selectToggleNftLikeData = createSelector(
  selectToggleNftLikeState,
  (state) => ({
    loading: state.loading,
    error: state.error,
    success: state.success,
    isLiked: state.isLikedCollection,
    idLike: state.idLike,
    idToggleLike: state.idToggleLike,

    loading: state.loadingToggleLike,
    error: state.errorMsgToggleLike,
    success: state.successToggleLike,
    isLiked: state.isLiked,
  })
);

//ToggleNftLike2
const selectToggleNftLikeState2 = (state) => state.ToggleNftLike;
export const selectToggleNftLikeData2 = createSelector(
  selectToggleNftLikeState2,
  (state) => ({
    idToggleLike: state.idToggleLike,

    loading: state.loadingToggleLike,
    error: state.errorMsgToggleLike,
    success: state.successToggleLike,
    isLiked: state.isLiked,
  })
);

//selectShippingAddressData
const selectShippingAddressState = (state) => state.ShippingAddressReducer;
export const selectShippingAddressData = createSelector(
  selectShippingAddressState,
  (state) => ({
    loadingShippingAddress: state.loadingShippingAddress,
    successShippingAddress: state.successShippingAddress,
    errorShippingAddress: state.errorShippingAddress,
    MessageShippingAddress: state.MessageShippingAddress,
    ShippingAddress: state.ShippingAddress,

    loadingShippingAddressDetails: state.loadingShippingAddressDetails,
    successShippingAddressDetails: state.successShippingAddressDetails,
    errorShippingAddressDetails: state.errorShippingAddressDetails,
    MessageShippingAddressDetails: state.MessageShippingAddressDetails,
    ShippingAddressDetails: state.ShippingAddressDetails,

    loadingProvinces: state.loadingProvinces,
    successProvinces: state.successProvinces,
    errorProvinces: state.errorProvinces,
    MessageProvinces: state.MessageProvinces,
    Provinces: state.Provinces,

    loadingCities: state.loadingCities,
    successCities: state.successCities,
    errorCities: state.errorCities,
    MessageCities: state.MessageCities,
    Cities: state.Cities,

    loadingCountry: state.loadingCountry,
    successCountry: state.successCountry,
    errorCountry: state.errorCountry,
    MessageCountry: state.MessageCountry,
    Country: state.Country,
  })
);

//OrderConfirmation
const selectOrderConfirmationState = (state) => state.OrderConfirmation;
export const selectOrderConfirmationData = createSelector(
  selectOrderConfirmationState,
  (state) => ({
    errorMsgorderconfirmation: state.errorMsgorderconfirmation,
    loadingorderconfirmation: state.loadingorderconfirmation,
    errororderconfirmation: state.errororderconfirmation,
    successorderconfirmation: state.successorderconfirmation,
    nft: state.nft,
  })
);

//Nsfw
const selectNsfwState = (state) => state.Nsfw;
export const selectNsfwData = createSelector(selectNsfwState, (state) => ({
  errorNsfwNfts: state.errorNsfwNfts,
  successNsfwNfts: state.successNsfwNfts,
  loadingNsfwNfts: state.loadingNsfwNfts,
  nsfwNfts: state.nsfwNfts,
}));

//Inspire
const selectInspireState = (state) => state.Inspire;
export const selectInspireData = createSelector(
  selectInspireState,
  (state) => ({
    errorInspire: state.errorInspire,
    loadingInspire: state.loadingInspire,
    successInspire: state.successInspire,
    nfts: state.nfts,
  })
);

//ReferralDashboard
const selectReferralDashboardState = (state) => state.ReferralDashboard;
export const selectReferralDashboardData = createSelector(
  selectReferralDashboardState,
  (state) => ({
    errorMsgHeroUsers: state.errorMsgHeroUsers,
    loadingHeroUsers: state.loadingHeroUsers,
    errorHeroUsers: state.errorHeroUsers,
    successHeroUsers: state.successHeroUsers,
    users: state.users,
  })
);

//AssetPortfolio
const selectAssetPortfolioState = (state) => state.AssetPortfolio;
export const selectAssetPortfolioData = createSelector(
  selectAssetPortfolioState,
  (state) => ({
    errorMsgAssetPortfolio: state.errorMsgAssetPortfolio,
    loadingAssetPortfolio: state.loadingAssetPortfolio,
    errorAssetPortfolio: state.errorAssetPortfolio,
    successAssetPortfolio: state.successAssetPortfolio,
    assetData: state.assetData,
  })
);

//GeneralSearchReducer
const selectgeneralSearchsState = (state) => state.GeneralSearchReducer;
export const selectgeneralSearchsData = createSelector(
  selectgeneralSearchsState,
  (state) => ({
    generalSearchs: state.generalSearchs,
    generalSearchsSuccess: state.generalSearchsSuccess,
    generalSearchsError: state.generalSearchsError,
    generalSearchsLoading: state.generalSearchsLoading,
    generalSearchsMsg: state.generalSearchsMsg,
  })
);

//TwitterStakesReducer
const selectStakeState = (state) => state.TwitterStakesReducer;
export const selectStakeData = createSelector(selectStakeState, (state) => ({
  getMyStakeSuccessMsg: state.getMyStakeSuccessMsg,
  getMyStake: state.getMyStake,
  getMyStakeError: state.getMyStakeError,
  getMyStakeSuccess: state.getMyStakeSuccess,
  getMyStakeLoading: state.getMyStakeLoading,
  getMyStakeMessage: state.getMyStakeMessage,

  cancelStakeSuccessMsg: state.cancelStakeSuccessMsg,
  cancelStake: state.cancelStake,
  cancelStakeError: state.cancelStakeError,
  cancelStakeSuccess: state.cancelStakeSuccess,
  cancelStakeLoading: state.cancelStakeLoading,
  cancelStakeMessage: state.cancelStakeMessage,

  requestStakeSuccessMsg: state.requestStakeSuccessMsg,
  requestStake: state.requestStake,
  requestStakeError: state.requestStakeError,
  requestStakeSuccess: state.requestStakeSuccess,
  requestStakeLoading: state.requestStakeLoading,
  requestStakeMessage: state.requestStakeMessage,
}));

//DeleteNft
const selectDeleteNftState = (state) => state.DeleteNft;
export const selectDeleteNftData = createSelector(
  selectDeleteNftState,
  (state) => ({
    errorMsg: state.errorMsg,
    loading: state.loading,
    error: state.error,
    success: state.success,
    Id: state.Id,
  })
);

//ReportNft
const selectReportNftState = (state) => state.ReportNft;
export const selectReportNftData = createSelector(
  selectReportNftState,
  (state) => ({
    errorMsgReportNft: state.errorMsgReportNft,
    loadingReportNft: state.loadingReportNft,
    errorReportNft: state.errorReportNft,
    successReportNft: state.successReportNft,
    ReportNftText: state.ReportNftText,
  })
);

//HeroSection
const selectHeroSectionState = (state) => state.HeroSection;
export const selectHeroSectionData = createSelector(
  selectHeroSectionState,
  (state) => ({
    errorMsgHeroNfts: state.errorMsgHeroNfts,
    loadingHeroNfts: state.loadingHeroNfts,
    errorHeroNfts: state.errorHeroNfts,
    successHeroNfts: state.successHeroNfts,
    nfts: state.nfts,

    mintedCount: state.mintedCount,
  })
);

//selectMuseumData
const selectMuseumState = (state) => state.Museum;
export const selectMuseumData = createSelector(selectMuseumState, (state) => ({
  nfts: state.nfts,
}));

//MuseumCalendar
const selectMuseumCalendarState = (state) => state.MuseumCalendar;
export const selectMuseumCalendarData = createSelector(
  selectMuseumCalendarState,
  (state) => ({
    errorMsgCalendarNfts: state.errorMsgCalendarNfts,
    loadingCalendarNfts: state.loadingCalendarNfts,
    errorCalendarNfts: state.errorCalendarNfts,
    successCalendarNfts: state.successCalendarNfts,
    nfts: state.nfts,
  })
);

//MuseumCurrentWeek
const selectMuseumCurrentWeekState = (state) => state.MuseumCurrentWeek;
export const selectMuseumCurrentWeekData = createSelector(
  selectMuseumCurrentWeekState,
  (state) => ({
    errorMsgCurrentWeek: state.errorMsgCurrentWeek,
    loadingCurrentWeek: state.loadingCurrentWeek,
    errorCurrentWeek: state.errorCurrentWeek,
    successCurrentWeek: state.successCurrentWeek,
    week: state.week,
  })
);

//MuseumUpcoming
const selectMuseumUpcomingState = (state) => state.MuseumUpcoming;
export const selectMuseumUpcomingData = createSelector(
  selectMuseumUpcomingState,
  (state) => ({
    errorMsgMuseumUpcomingNfts: state.errorMsgMuseumUpcomingNfts,
    loadingMuseumUpcomingNfts: state.loadingMuseumUpcomingNfts,
    errorMuseumUpcomingNfts: state.errorMuseumUpcomingNfts,
    successMuseumUpcomingNfts: state.successMuseumUpcomingNfts,
    nftsUpcoming: state.nftsUpcoming,
  })
);

//MuseumReleased
const selectMuseumReleasedState = (state) => state.MuseumReleased;
export const selectMuseumReleasedData = createSelector(
  selectMuseumReleasedState,
  (state) => ({
    errorMsgMuseumNfts: state.errorMsgMuseumNfts,
    loadingMuseumNfts: state.loadingMuseumNfts,
    errorMuseumNfts: state.errorMuseumNfts,
    successMuseumNfts: state.successMuseumNfts,
    nfts: state.nfts,
  })
);

//MuseumAuction
const selectMuseumAuctionState = (state) => state.MuseumAuction;
export const selectMuseumAuctionData = createSelector(
  selectMuseumAuctionState,
  (state) => ({
    errorMsgAuction: state.errorMsgAuction,
    loadingAuction: state.loadingAuction,
    errorAuction: state.errorAuction,
    successAuction: state.successAuction,
    nft: state.nft,
  })
);

//MuseumWelcome
const selectMuseumWelcomeState = (state) => state.MuseumWelcome;
export const selectMuseumWelcomeData = createSelector(
  selectMuseumWelcomeState,
  (state) => ({
    errorMsgMuseumHeroNfts: state.errorMsgMuseumHeroNfts,
    loadingMuseumHeroNfts: state.loadingMuseumHeroNfts,
    errorMuseumHeroNfts: state.errorMuseumHeroNfts,
    successMuseumHeroNfts: state.successMuseumHeroNfts,
    nfts: state.nfts,
  })
);

//AllNftCard
const selectAllNftCardState = (state) => state.AllNftCard;
export const selectAllNftCardData = createSelector(
  selectAllNftCardState,
  (state) => ({
    errorAllNftCard: state.errorAllNftCard,
    loadingAllNftCard: state.loadingAllNftCard,
    successAllNftCard: state.successAllNftCard,
    nfts: state.nfts,
  })
);

//TopPhotographers
const selectTopPhotographersState = (state) => state.TopPhotographers;
export const selectTopPhotographersData = createSelector(
  selectTopPhotographersState,
  (state) => ({
    //photographersReferrals
    errorMsgTopPhotographersReferrals: state.errorMsgTopPhotographersReferrals,
    loadingTopPhotographersReferrals: state.loadingTopPhotographersReferrals,
    errorTopPhotographersReferrals: state.errorTopPhotographersReferrals,
    successTopPhotographersReferrals: state.successTopPhotographersReferrals,
    photographersReferrals: state.photographersReferrals,

    //photographersSell
    errorMsgTopPhotographersSell: state.errorMsgTopPhotographersSell,
    loadingTopPhotographersSell: state.loadingTopPhotographersSell,
    errorTopPhotographersSell: state.errorTopPhotographersSell,
    successTopPhotographersSell: state.successTopPhotographersSell,
    photographersSell: state.photographersSell,

    //photographersMinted
    errorMsgTopPhotographersMinted: state.errorMsgTopPhotographersMinted,
    loadingTopPhotographersMinted: state.loadingTopPhotographersMinted,
    errorTopPhotographersMinted: state.errorTopPhotographersMinted,
    successTopPhotographersMinted: state.successTopPhotographersMinted,
    photographersMinted: state.photographersMinted,

    errorMsgTopPhotographersListed: state.errorMsgTopPhotographersListed,
    loadingTopPhotographersListed: state.loadingTopPhotographersListed,
    errorTopPhotographersListed: state.errorTopPhotographersListed,
    successTopPhotographersListed: state.successTopPhotographersListed,
    photographersListed: state.photographersListed,

    loadingTopPhotographers: state.loadingTopPhotographers,
    photographers: state.photographers,
  })
);

//Web3Action
const selectWeb3ActionState = (state) => state.Web3Action;
export const selectWeb3ActionData = createSelector(
  selectWeb3ActionState,
  (state) => ({
    errorMsgMint: state.errorMsgMint,
    loadingMint: state.loadingMint,
    errorMint: state.errorMint,
    successMint: state.successMint,
    mint: state.mint,
  })
);

//GetNftHistory
const selectGetNftHistoryState = (state) => state.GetNftHistory;
export const selectGetNftHistoryData = createSelector(
  selectGetNftHistoryState,
  (state) => ({
    loading: state.loading,
    success: state.success,
    error: state.error,
    history: state.history,
  })
);

//NotificationSettings
const selectNotificationSettingsState = (state) => state.NotificationSettings;
export const selectNotificationSettingsData = createSelector(
  selectNotificationSettingsState,
  (state) => ({
    errorMsgNotificationSettings: state.errorMsgNotificationSettings,
    loadingNotificationSettings: state.loadingNotificationSettings,
    errorNotificationSettings: state.errorNotificationSettings,
    successNotificationSettings: state.successNotificationSettings,
    notifs: state.notifs,
  })
);

//FavoritesNftCard
const selectFavoritesNftCardState = (state) => state.FavoritesNftCard;
export const selectFavoritesNftCardData = createSelector(
  selectFavoritesNftCardState,
  (state) => ({
    errorFavoritesNftCard: state.errorFavoritesNftCard,
    loadingFavoritesNftCard: state.loadingFavoritesNftCard,
    successFavoritesNftCard: state.successFavoritesNftCard,
    nftsFavorites: state.nftsFavorites,
    loadingFavoritesCollection: state.loadingFavoritesCollection,
    successFavoritesCollection: state.successFavoritesCollection,
    errorFavoritesCollection: state.errorFavoritesCollection,
    nftsFavoritesCollection: state.CollectionFavorites,
  })
);

//Referral
const selectReferralState = (state) => state.Referral;
export const selectReferralData = createSelector(
  selectReferralState,
  (state) => ({
    loadingReferral: state.loadingReferral,
    successReferral: state.successReferral,
    errorReferral: state.errorReferral,
    Referral: state.Referral,
  })
);

//Following
const selectFollowing = (state) => state.Following;
export const createSelectorFollowing = createSelector(
  selectFollowing,
  (state) => ({
    errorFollowing: state.errorFollowing,
    loadingFollowing: state.loadingFollowing,
    successFollowing: state.successFollowing,
    Following: state.Following,
  })
);

//Follower
const selectFollower = (state) => state.Follower;
export const createSelectorFollower = createSelector(
  selectFollower,
  (state) => ({
    loadingFollower: state.loadingFollower,
    successFollower: state.successFollower,
    errorFollower: state.errorFollower,
    Follower: state.Follower,
  })
);

//ArchiveNftCard
const selectArchiveNftCardState = (state) => state.ArchiveNftCard;
export const selectArchiveNftCardData = createSelector(
  selectArchiveNftCardState,
  (state) => ({
    errorArchiveNftCard: state.errorArchiveNftCard,
    loadingArchiveNftCard: state.loadingArchiveNftCard,
    successArchiveNftCard: state.successArchiveNftCard,
    nftsArchive: state.nftsArchive,
  })
);

//CollaborationNftCard
const selectCollaborationNftCardState = (state) => state.CollaborationNftCard;
export const selectCollaborationNftCardData = createSelector(
  selectCollaborationNftCardState,
  (state) => ({
    errorCollaborationNftCard: state.errorCollaborationNftCard,
    loadingCollaborationNftCard: state.loadingCollaborationNftCard,
    successCollaborationNftCard: state.successCollaborationNftCard,
    nftsCollaboration: state.nftsCollaboration,
  })
);

//CollectedNftCard
const selectCollectedNftCardState = (state) => state.CollectedNftCard;
export const selectCollectedNftCardData = createSelector(
  selectCollectedNftCardState,
  (state) => ({
    errorCollectedNftCard: state.errorCollectedNftCard,
    loadingCollectedNftCard: state.loadingCollectedNftCard,
    successCollectedNftCard: state.successCollectedNftCard,
    nftsCollected: state.nftsCollected,
  })
);

//AccountCollectionNfts
const selectAccountCollectionNftsState = (state) => state.AccountCollectionNfts;
export const selectAccountCollectionNftsData = createSelector(
  selectAccountCollectionNftsState,
  (state) => ({
    errorAccountCollectionNfts: state.errorAccountCollectionNfts,
    loadingAccountCollectionNfts: state.loadingAccountCollectionNfts,
    successAccountCollectionNfts: state.successAccountCollectionNfts,
    nftsAccountCollectionNfts: state.nftsAccountCollectionNfts,
  })
);

//CreatedNftCard
const selectCreatedNftCardState = (state) => state.CreatedNftCard;
export const selectCreatedNftCardData = createSelector(
  selectCreatedNftCardState,
  (state) => ({
    errorCreatedNftCard: state.errorCreatedNftCard,
    loadingCreatedNftCard: state.loadingCreatedNftCard,
    successCreatedNftCard: state.successCreatedNftCard,
    nftsCreated: state.nftsCreated,
  })
);

//ManageMyBidsNftCard
const selectManageMyBidsNftCardState = (state) => state.ManageMyBidsNftCard;
export const selectManageMyBidsNftCardData = createSelector(
  selectManageMyBidsNftCardState,
  (state) => ({
    errorManageMyBidsNftCard: state.errorManageMyBidsNftCard,
    loadingManageMyBidsNftCard: state.loadingManageMyBidsNftCard,
    successManageMyBidsNftCard: state.successManageMyBidsNftCard,
    nftsManageMyBids: state.nftsManageMyBids,
  })
);

//MuseumNftCard
const selectMuseumNftCardState = (state) => state.MuseumNftCard;
export const selectMuseumNftCardData = createSelector(
  selectMuseumNftCardState,
  (state) => ({
    errorMuseumNftCard: state.errorMuseumNftCard,
    loadingMuseumNftCard: state.loadingMuseumNftCard,
    successMuseumNftCard: state.successMuseumNftCard,
    nftsMuseum: state.nftsMuseum,
  })
);

//MyTransactions
const selectMyTransactionsState = (state) => state.MyTransactions;
export const selectMyTransactionsData = createSelector(
  selectMyTransactionsState,
  (state) => ({
    errorMyTransactions: state.errorMyTransactions,
    loadingMyTransactions: state.loadingMyTransactions,
    successMyTransactions: state.successMyTransactions,
    transaction: state.transaction,
  })
);

//TopArtists
const selectTopArtistsState = (state) => state.TopArtists;
export const selectTopArtistsData = createSelector(
  selectTopArtistsState,
  (state) => ({
    errorTopArtists: state.errorTopArtists,
    loadingTopArtists: state.loadingTopArtists,
    successTopArtists: state.successTopArtists,
    artist: state.artist,
  })
);

//TopCollectors
const selectTopCollectorsState = (state) => state.TopCollectors;
export const selectTopCollectorsData = createSelector(
  selectTopCollectorsState,
  (state) => ({
    errorMsgTopCollectors: state.errorMsgTopCollectors,
    loadingTopCollectors: state.loadingTopCollectors,
    errorTopCollectors: state.errorTopCollectors,
    successTopCollectors: state.successTopCollectors,
    users: state.users,
  })
);

//TopCurators
const selectTopCuratorsState = (state) => state.TopCurators;
export const selectTopCuratorsData = createSelector(
  selectTopCuratorsState,
  (state) => ({
    errorMsgHeroUsers: state.errorMsgHeroUsers,
    loadingHeroUsers: state.loadingHeroUsers,
    errorHeroUsers: state.errorHeroUsers,
    successHeroUsers: state.successHeroUsers,
    users: state.users,
  })
);

//Explore
const selectExploreState = (state) => state.Explore;
export const selectExploreData = createSelector(
  selectExploreState,
  (state) => ({
    errorExplore: state.errorExplore,
    loadingExplore: state.loadingExplore,
    successExplore: state.successExplore,
    nfts: state.nfts,
    //*Search
    errorSearchExplore: state.errorSearchExplore,
    loadingSearchExplore: state.loadingSearchExplore,
    successSearchExplore: state.successSearchExplore,
    SearchNfts: state.SearchNfts,
  })
);

//customTaskActionReducer
const selectcustomTaskActionState = (state) => state.customTaskActionReducer;
export const selectcustomTaskAction = createSelector(
  selectcustomTaskActionState,
  (state) => ({
    myCustomTaskActionList: state.myCustomTaskActionList,
    myCustomTaskActionListError: state.myCustomTaskActionListError,
    myCustomTaskActionListSuccess: state.myCustomTaskActionListSuccess,
    myCustomTaskActionListLoading: state.myCustomTaskActionListLoading,
    myCustomTaskActionListMsg: state.myCustomTaskActionListMsg,

    customTaskActionUpdate: state.customTaskActionUpdate,
    customTaskActionUpdateError: state.customTaskActionUpdateError,
    customTaskActionUpdateSuccess: state.customTaskActionUpdateSuccess,
    customTaskActionUpdateLoading: state.customTaskActionUpdateLoading,
    customTaskActionUpdateMsg: state.customTaskActionUpdateMsg,
  })
);

//TwitterWithdrawalsReducer
const selectWithdrawalState = (state) => state.TwitterWithdrawalsReducer;
export const selectWithdrawalData = createSelector(
  selectWithdrawalState,
  (state) => ({
    getMyWithdrawalSuccessMsg: state.getMyWithdrawalSuccessMsg,
    getMyWithdrawal: state.getMyWithdrawal,
    getMyWithdrawalError: state.getMyWithdrawalError,
    getMyWithdrawalSuccess: state.getMyWithdrawalSuccess,
    getMyWithdrawalLoading: state.getMyWithdrawalLoading,
    getMyWithdrawalMessage: state.getMyWithdrawalMessage,

    requestWithdrawalSuccessMsg: state.requestWithdrawalSuccessMsg,
    requestWithdrawal: state.requestWithdrawal,
    requestWithdrawalError: state.requestWithdrawalError,
    requestWithdrawalSuccess: state.requestWithdrawalSuccess,
    requestWithdrawalLoading: state.requestWithdrawalLoading,
    requestWithdrawalMessage: state.requestWithdrawalMessage,

    cancelWithdrawalSuccessMsg: state.cancelWithdrawalSuccessMsg,
    cancelWithdrawal: state.cancelWithdrawal,
    cancelWithdrawalError: state.cancelWithdrawalError,
    cancelWithdrawalSuccess: state.cancelWithdrawalSuccess,
    cancelWithdrawalLoading: state.cancelWithdrawalLoading,
    cancelWithdrawalMessage: state.cancelWithdrawalMessage,
  })
);

//TwitterPointsReducer
const selectGetMyPointsState = (state) => state.TwitterPointsReducer;
export const selectGetMyPointsData = createSelector(
  selectGetMyPointsState,
  (state) => ({
    getMyPointsSuccessMsg: state.getMyPointsSuccessMsg,
    getMyPointsError: state.getMyPointsError,
    getMyPointsSuccess: state.getMyPointsSuccess,
    getMyPointsLoading: state.getMyPointsLoading,
    getMyPointsMessage: state.getMyPointsMessage,
    getMyPoints: state.getMyPoints,

    TwitterSetting: state.TwitterSetting,

    postMyActivitiesSuccessMsg: state.postMyActivitiesSuccessMsg,
    postMyActivities: state.postMyActivities,
    postMyActivitiesError: state.postMyActivitiesError,
    postMyActivitiesSuccess: state.postMyActivitiesSuccess,
    postMyActivitiesLoading: state.postMyActivitiesLoading,
    postMyActivitiesMessage: state.postMyActivitiesMessage,

    postMySeed: state.postMySeed,
    postMySeedError: state.postMySeedError,
    postMySeedSuccess: state.postMySeedSuccess,
    postMySeedLoading: state.postMySeedLoading,
    postMySeedMessage: state.postMySeedMessage,
  })
);

//VerifyTwitter
const selectVerifyTwitter = (state) => state.VerifyTwitter;
export const selectVerifyTwitterData = createSelector(
  selectVerifyTwitter,
  (state) => ({
    verifyTwitterSuccessMsg: state.verifyTwitterSuccessMsg,
    verifyTwitter: state.verifyTwitter,
    verifyTwitterError: state.verifyTwitterError,
    verifyTwitterSuccess: state.verifyTwitterSuccess,
    verifyTwitterLoading: state.verifyTwitterLoading,
    verifyTwitterMessage: state.verifyTwitterMessage,
  })
);

//TwitterFarm
const ListpointsState = (state) => state.TwitterFarm;
export const selectListpointsState = createSelector(
  ListpointsState,
  (state) => ({
    postTwitterMyPointsData: state.postTwitterMyPointsData,
    postTwitterMyPointsMsg: state.postTwitterMyPointsMsg,
    postTwitterMyPointsError: state.postTwitterMyPointsError,
    postTwitterMyPointsSuccess: state.postTwitterMyPointsSuccess,
    postTwitterMyPointsLoading: state.postTwitterMyPointsLoading,
  })
);

//NewListing
const selectNewListingState = (state) => state.NewListing;
export const selectNewListingData = createSelector(
  selectNewListingState,
  (state) => ({
    successNewListingNfts: state.successNewListingNfts,
    nfts: state.nfts,
  })
);

//GetSettingsCategories
const selectCategoriesState = (state) => state.GetSettingsCategories;
export const selectCategoriesData = createSelector(
  selectCategoriesState,
  (state) => ({
    loadingSettingsCategories: state.loadingSettingsCategories,
    successSettingsCategories: state.successSettingsCategories,
    errorSettingsCategories: state.errorSettingsCategories,
    MessageSettingsCategories: state.MessageSettingsCategories,
    SettingsCategories: state.SettingsCategories,
  })
);

//ExclusiveCollections
const selectExclusiveCollectionsState = (state) => state.ExclusiveCollections;
export const selectExclusiveNftsData = createSelector(
  selectExclusiveCollectionsState,
  (state) => ({
    loadingExclusiveNfts: state.loadingExclusiveNfts,
    nfts: state.nfts,

    loadingPostExclusiveNfts: state.loadingPostExclusiveNfts,
    successPostExclusiveNfts: state.successPostExclusiveNfts,
    PostExclusiveNfts: state.PostExclusiveNfts,
  })
);

//Testimonial
const selectTestimonialState = (state) => state.Testimonial;
export const selectTestimonialData = createSelector(
  selectTestimonialState,
  (state) => ({
    comment: state.Testimonial,
  })
);

//TrendingArtworks
const selectTrendingArtworksState = (state) => state.TrendingArtworks;
export const selectTrendingNftsData = createSelector(
  selectTrendingArtworksState,
  (state) => ({
    errorMsgTrendingNfts: state.errorMsgTrendingNfts,
    loadingTrendingNfts: state.loadingTrendingNfts,
    errorTrendingNfts: state.errorTrendingNfts,
    successTrendingNfts: state.successTrendingNfts,
    nfts: state.nfts,
  })
);

//selectHotBidsData
const selectHotBidsState = (state) => state.HotBids;
export const selectHotBidsData = createSelector(
  selectHotBidsState,
  (state) => ({
    errorMsgHotBids: state.errorMsgHotBids,
    loadingHotBids: state.loadingHotBids,
    errorHotBids: state.errorHotBids,
    successHotBids: state.successHotBids,
    nfts: state.nfts,
  })
);

//selectHotCollectionData
const selectHotCollectionState = (state) => state.HotCollections;
export const selectHotCollectionData = createSelector(
  selectHotCollectionState,
  (state) => ({
    nfts: state.nfts,
  })
);

//createSelectorReportUser
const selectReportUser = (state) => state.ReportUser;
export const createSelectorReportUser = createSelector(
  selectReportUser,
  (state) => ({
    errorMsgReportUser: state.errorMsgReportUser,
    loadingReportUser: state.loadingReportUser,
    errorReportUser: state.errorReportUser,
    successReportUser: state.successReportUser,
    ReportUser: state.ReportUser,
  })
);

//FeaturedArtists
const selectFeaturedArtistsState = (state) => state.FeaturedArtists;
export const selectFeaturedArtistsData = createSelector(
  selectFeaturedArtistsState,
  (state) => ({
    loadingFeaturedNfts: state.loadingFeaturedNfts,
    nfts: state.nfts,
  })
);

//NewsLetter
const selectJoinNewslatterState = (state) => state.NewsLetter;
export const selectJoinNewslatterData = createSelector(
  selectJoinNewslatterState,
  (state) => ({
    success: state.success,
    news: state.news,
  })
);

//selectAnnouncementNewsData
const selectAnnouncementNewsState = (state) => state.AnnouncementNews;
export const selectAnnouncementNewsData = createSelector(
  selectAnnouncementNewsState,
  (state) => ({
    successHeroUsers: state.successHeroUsers,
    news: state.news,
  })
);

//leyout
const selectLayoutModeTypeState = (state) => state.Layout;
export const selectLayoutModeTypesData = createSelector(
  selectLayoutModeTypeState,
  (state) => ({
    layoutModeType: state.layoutModeType,
  })
);

//selectRecentlySoldData
const selectRecentlySoldState = (state) => state.RecentlySold;
export const selectRecentlySoldData = createSelector(
  selectRecentlySoldState,
  (state) => ({
    successRecentlySoldNfts: state.successRecentlySoldNfts,
    nfts: state.nfts,
  })
);
