function AwardsPhotosHero({ homeStyle }) {
  return (
    <>
      <div className={homeStyle["hero_awards"]}></div>
      <div className={homeStyle["awards_text"]}>AWARDS</div>
      <div
        className={`${homeStyle["text_colorful"]} ${homeStyle["photos_text"]}`}
      >
        PHOTOS
      </div>
      <p className={homeStyle["awards_description"]}>
        A premier photography contest recognizing and rewarding excellence. Open
        to both amateur and professional photographers, the platform, in
        collaboration with Eurasia, FIAP, and SEED.Photo provides a global
        community to showcase your talent and compete for cash prizes and
        recognition. Join us today and celebrate the art of photography.
      </p>
      <div className={homeStyle["awards_summary"]}>
        <div
          className={`${homeStyle["home__awardsPtotos__color"]} ${homeStyle["first"]} `}
        >
          Creative Eurasia <b>NFT</b> Photo Salon 2023
        </div>
        <div className={` ${homeStyle["home__awardsPtotos__color"]} `}>
          <b>FIAP</b> Patronage # 2023/428
        </div>
      </div>
    </>
  );
}

export default AwardsPhotosHero;
